import React from "react";
import RouteAnimation from "../../components/helper/RouteAnimation";
import SiteHeroSection from "../../components/SiteMap/SiteHeroSection";
import QuickLinks from "../../components/SiteMap/QuickLinks";
import ServicesLink from "../../components/SiteMap/ServicesLink";
import HireDeveloperLinks from "../../components/SiteMap/HireDeveloperLinks";

const SiteMap = () => {
  return (
    <>
      <RouteAnimation>
        <SiteHeroSection />
        <QuickLinks />
        <ServicesLink />
        <HireDeveloperLinks />
      </RouteAnimation>
    </>
  );
};

export default SiteMap;
