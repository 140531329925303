import React from "react";
import Heading from "../../components/helper/Heading";
import { motion } from "framer-motion";

const Skills = () => {
  const transition = { duration: 5, type: "spring" };

  return (
    <div className="bg-[#f3f4fe] w-full mt-20">
      <div className="max-w-[95%] md:max-w-[80%] mx-auto md:pt-24 pt-16 md:pb-24 pb-16">
        <Heading
          styles="absolute w-full top-6 esm:top-12 text-center mx-auto"
          image="/assets/images/home/skill.svg"
          text="Skills & Services"
        />
        <div>
          <div className="lg:hidden grid grid-cols-2 gap-5 mt-20 ">
            <div
              className="w-[130px] fold:w-[149px] h-[134px] bg-white rounded-xl shadow-xl mx-auto"
              data-aos="fade-right"
            >
              <div className="pt-1">
                <img
                  className="mt-1 mx-auto"
                  src="/assets/images/home/skillmobile.png"
                  alt=""
                />
              </div>
              <div className="text-center mt-3">
                <h1 className="text-[18px] text-[red]">Mobile Application</h1>
              </div>
            </div>
            <div
              className="w-[130px] fold:w-[149px] h-[134px] bg-white rounded-xl shadow-xl mx-auto"
              data-aos="fade-left"
            >
              <div className="pt-5">
                <img
                  className="mt-1 mx-auto"
                  src="/assets/images/home/skilluiux.png"
                  alt=""
                />
              </div>
              <div className="text-center mt-3">
                <h1 className="text-[18px] text-[red]">UI/UX</h1>
              </div>
            </div>
            <div
              className="w-[130px] fold:w-[149px] h-[134px] bg-white rounded-xl shadow-xl mx-auto"
              data-aos="fade-right"
            >
              <div>
                <img
                  className="mt-3 mx-auto"
                  src="/assets/images/home/skillcloud.png"
                  alt=""
                />
              </div>
              <div className="text-center mt-3">
                <h1 className="text-[18px] text-[#83cd29] px-1">
                  Cloud Technology
                </h1>
              </div>
            </div>
            <div
              className="w-[130px] fold:w-[149px] h-[134px] bg-white rounded-xl shadow-xl mx-auto"
              data-aos="fade-left"
            >
              <div className="pt-1">
                <img
                  className="mt-1 mx-auto"
                  src="/assets/images/home/skilljs.png"
                  alt=""
                />
              </div>

              <div className="text-center mt-3">
                <h1 className="text-[18px] text-[#83cd29]">
                  Javascript Framework
                </h1>
              </div>
            </div>
            <div
              className="w-[130px] fold:w-[149px] h-[134px] bg-white rounded-xl shadow-xl mx-auto"
              data-aos="fade-right"
            >
              <div className="pt-1">
                <img
                  className="mt-4 mx-auto"
                  src="/assets/images/home/skilldatabase.png"
                  alt=""
                />
              </div>
              <div className="text-center mt-3">
                <h1 className="text-[18px] text-[#1377c3]">Database</h1>
              </div>
            </div>
            <div
              className="w-[130px] fold:w-[149px] h-[134px] bg-white rounded-xl shadow-xl mx-auto"
              data-aos="fade-left"
            >
              <div className="pt-5">
                <img
                  className="mt-1 mx-auto"
                  src="/assets/images/home/skilltags.png"
                  alt=""
                />
              </div>
              <div className="text-center mt-3">
                <h1 className="text-[18px] text-[#1377c3]">Web Technology</h1>
              </div>
            </div>
          </div>
          <div className=" md:hidden w-[149px] h-[134px] bg-white rounded-xl shadow-xl mx-auto mt-5">
            <div className="pt-1">
              <img
                className="mt-4 mx-auto"
                src="/assets/images/home/skillgroup.png"
                alt=""
              />
            </div>
            <div className="text-center mt-3">
              <h1 className="text-[18px] text-[#006b73]">Communication</h1>
            </div>
          </div>
        </div>

        <div className="relative mt-10 md:mt-[200px]">
          <img
            className="mx-auto"
            src="/assets/images/home/skillsmainbg.png"
            alt=""
          />

          <motion.img
            initial={{ top: "200%" }}
            whileInView={{ top: "2%" }}
            transition={transition}
            src="/assets/images/home/skillrocket.png"
            alt=""
            className="absolute top-0 right-0 left-0 bottom-0 m-auto z-50 w-[200px] md:w-[280px]"
          />
          <div
            className="hidden md:block absolute w-[149px] h-[134px] bg-white rounded-xl top-[-670px] left-0 right-0 bottom-0 m-auto  shadow-xl"
            data-aos="fade-down"
          >
            <div className="h-[134px] text-center flex flex-col items-center justify-center">
              <div>
                <img src="/assets/images/home/skillgroup.png" alt="" />
              </div>
              <div className="mt-2">
                <h1 className="text-[18px] text-[#006b73]">Communication</h1>
              </div>
            </div>
          </div>
          <div
            className="hidden lg:block absolute w-[149px] h-[134px] bg-white rounded-xl  top-[-100px] left-0 right-[670px] bottom-0 m-auto shadow-xl"
            data-aos="fade-right"
          >
            <div className="h-[134px] text-center flex flex-col items-center justify-center">
              <div>
                <img src="/assets/images/home/skillmobile.png" alt="" />
              </div>
              <div>
                <h1 className="text-[18px] text-[red]">Mobile Application</h1>
              </div>
            </div>
          </div>
          <div
            className="hidden lg:block absolute w-[149px] h-[134px] bg-white rounded-xl bottom-[-300px] left-[600px] right-0 top-0 m-auto  shadow-xl"
            data-aos="fade-left"
          >
            <div className="h-[134px] text-center flex flex-col items-center justify-center">
              <div>
                <img src="/assets/images/home/skillcloud.png" alt="" />
              </div>
              <div>
                <h1 className="text-[18px] text-[#83cd29] px-1">
                  Cloud Technology
                </h1>
              </div>
            </div>
          </div>
          <div
            className="hidden lg:block absolute w-[149px] h-[134px] bg-white rounded-xl top-[-530px] left-[500px] right-0 bottom-0 m-auto shadow-xl"
            data-aos="fade-left"
          >
            <div className="h-[134px] text-center flex flex-col items-center justify-center">
              <div>
                <img src="/assets/images/home/skilldatabase.png" alt="" />
              </div>
              <div className="mt-2">
                <h1 className="text-[18px] text-[#1377c3]">Database</h1>
              </div>
            </div>
          </div>

          <div
            className="hidden lg:block absolute w-[149px] h-[134px] bg-white rounded-xl bottom-[-300px] right-[600px] left-0 top-0 m-auto shadow-xl"
            data-aos="fade-right"
          >
            <div className="h-[134px] text-center flex flex-col items-center justify-center">
              <div>
                <img src="/assets/images/home/skilljs.png" alt="" />
              </div>

              <div>
                <h1 className="text-[18px] text-[#83cd29]">
                  Javascript Framework
                </h1>
              </div>
            </div>
          </div>
          <div
            className="hidden lg:block absolute w-[149px] h-[134px] bg-white rounded-xl top-[-100px] left-[670px] right-0 bottom-0 m-auto shadow-xl"
            data-aos="fade-left"
          >
            <div className="h-[134px] text-center flex flex-col items-center justify-center">
              <div>
                <img src="/assets/images/home/skilluiux.png" alt="" />
              </div>
              <div className="mt-2">
                <h1 className="text-[18px] text-[red]">UI/UX</h1>
              </div>
            </div>
          </div>
          <div
            className="hidden lg:block absolute w-[149px] h-[134px] bg-white rounded-xl top-[-530px] left-0 right-[500px] bottom-0 m-auto shadow-xl"
            data-aos="fade-right"
          >
            <div className="h-[134px] text-center flex flex-col items-center justify-center">
              <div>
                <img src="/assets/images/home/skilltags.png" alt="" />
              </div>
              <div className="mt-2">
                <h1 className="text-[18px] text-[#1377c3]">Web Technology</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <img
            data-aos="fade-up"
            data-aos-delay="1000"
            className=" mx-auto"
            src="/assets/images/home/skillrocketcloud.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Skills;
