import React from "react";
import { Link } from "react-router-dom";

const LinkButton = ({ text, pathName, linkColor, className }) => {
  const colors = {
    blue: "text-[#1A76B9] bg-[#F6FBFF] border-[#CAE0F1]",
    green: "text-[#2B747E] bg-[#FAFFFF] border-[#D1E3E5]",
    red: "text-[#CA5183] bg-[#FFF5F9] border-[#F4D4E1]",
  };
  return (
    <>
      <Link
        to={pathName}
        className={`border xl:text-[24px] text-[22px] font-medium text-center xl:py-4 py-3 ${colors[linkColor]} xl:w-[250px] sm:w-[200px] w-full block rounded-[16px] ${className} max-w-[300px]`}
      >
        {text}
      </Link>
    </>
  );
};

export default LinkButton;
