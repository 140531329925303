import React, { useEffect, useState } from "react";
import SucessSnackBar from "../../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../../components/SnackBars/SnackBar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../helper/api";
import { SERVICES, SMARTIMAGES } from "../../../helper/endpoints";

const Edit = ({
  myedit,
  editmodel,
  setEditmodel,
  getAllServicesData,
  setEditSucessSnackBarOpen,
  setEditWarningSnackBarOpen,
}) => {
  const [heroImage, setHeroImage] = useState();
  const [headerImage, setHeaderImage] = useState();
  const [iconImages, setIconImages] = useState([]);
  const [value, setValue] = useState({});
  const [description, setDescription] = useState("");

  const [descriptionArray, setDescriptionArray] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (iconImages) {
      console.log(iconImages, "This images");
      const formData = new FormData();
      iconImages.forEach(file => formData.append("images", file))
      formData.append("heroImages", heroImage);
      formData.append("headingImages", headerImage);
      formData.append("title", value.title);
      formData.append("header", value.header);
      formData.append("description", value.description);
      formData.append("points", JSON.stringify(descriptionArray));
      api
        .putWithToken(
          `${process.env.REACT_APP_API + SERVICES}?id=${myedit._id}`,
          formData
        )
        .then((res) => {
          console.log(res, "this is frontend response");
          setEditmodel(false);
          setHeroImage(null);
          getAllServicesData();
          setEditSucessSnackBarOpen(true);
        })
        .catch((err) => {
          console.log(err, "This is error");
          setEditWarningSnackBarOpen(true);
        });
    // }
  };
  const handelDescriptionAdd = () => {};

  useEffect(() => {
    setValue(myedit);
    setDescriptionArray(myedit.points);
  }, [editmodel]);

  return (
    <>
      {/* <SucessSnackBar
        open={sucessSnackBarOpen}
        setOpen={setSucessSnackBarOpen}
        text="Card Added Successfully !"
        type="success"
      />
      <ErrorSnackBar
        open={warningSnackBarOpen}
        setOpen={setWarningSnackBarOpen}
        text="Something Missing !"
        type="error"
      /> */}

      <Modal
        open={editmodel}
        onClose={() => {
          setEditmodel(false);
          setHeroImage(null);
          setHeaderImage(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-descriptionArray"
        style={{ overflow: "scroll" }}
      >
        <>
          <form onSubmit={handleSubmit} encType="encType='multipart/form-data'">
            <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[80%] overflow-y-scroll bg-white rounded-3xl p-5 md:p-10 w-[90%] md:w-[600px]">
              <div className="absolute top-2 right-2">
                <IconButton
                  onClick={() => {
                    setEditmodel(false);
                    setHeroImage(null);
                    setHeaderImage(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="mt-5">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  onChange={({ target }) =>
                    setValue({ ...value, title: target.value })
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Service Title"
                  value={value.title}
                  required
                />
              </div>
              <div className="mt-5">
                <label
                  htmlFor="header"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Header
                </label>
                <input
                  type="text"
                  id="header"
                  name="header"
                  onChange={({ target }) =>
                    setValue({ ...value, header: target.value })
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Service Title"
                  value={value.header}
                  required
                />
              </div>
              <div className="mt-5">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Description
                </label>
                <textarea
                  rows={4}
                  type="text"
                  id="description"
                  name="description"
                  onChange={({ target }) =>
                    setValue({ ...value, description: target.value })
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Service Title"
                  value={value.description}
                  required
                />
              </div>
              <div className="mt-5">
                <label
                  htmlFor="descriptionArray"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Points
                </label>
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <input
                      type="text"
                      id="descriptionArray"
                      name="descriptionArray"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={5}
                      placeholder="Enter Points"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div
                    className="bg-[teal] rounded-[50%] p-2 cursor-pointer"
                    onClick={handelDescriptionAdd}
                  >
                    <AddCircleOutlineIcon sx={{ color: "white" }} />
                  </div>
                </div>
                <ul className="mx-auto w-[96%] mt-5 space-y-2">
                  {descriptionArray?.map((value, index) => (
                    <li key={index} className="text-sm list-outside flex">
                      <span className="flex-1">{value}</span>
                      <span
                        className="bg-[teal] rounded-[50%] cursor-pointer"
                        onClick={() =>
                          setDescriptionArray((preValues) =>
                            preValues.filter((values) => values !== value)
                          )
                        }
                      >
                        <RemoveCircleOutlineIcon sx={{ color: "white" }} />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-5">
                <div className="mt-5">
                  <label
                    htmlFor="heroimg"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Hero Image
                  </label>
                  <input
                    id="heroimg"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="file"
                    name="smartimages"
                    onChange={(e) => setHeroImage(e.target.files[0])}
                  />
                </div>
                <div className="mt-5">
                  <img
                    className="mx-auto h-[100px] w-[100px]"
                    src={
                      heroImage
                        ? URL.createObjectURL(heroImage)
                        : process.env.REACT_APP_UPLOADS + myedit.heroImages
                    }
                    alt="uploded-img"
                  />
                </div>
                <div className="mt-5">
                  <label
                    htmlFor="headerimg"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Header Image
                  </label>
                  <input
                    id="headerimg"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="file"
                    name="smartimages"
                    onChange={(e) => setHeaderImage(e.target.files[0])}
                  />
                </div>
                <div className="mt-5">
                  <img
                    className="mx-auto h-[100px] w-[100px]"
                    src={
                      headerImage
                        ? URL.createObjectURL(headerImage)
                        : process.env.REACT_APP_UPLOADS + myedit.headingImages
                    }
                    alt="uploded-img"
                  />
                </div>
                <div className="mt-5">
                  <label
                    htmlFor="iconimg"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Icon Images
                  </label>
                  <input
                    multiple
                    id="iconimg"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="file"
                    name="iconimg"
                    onChange={(e) => setIconImages([...e.target.files])}
                  />
                </div>
                <div className="mt-5">
                  <div className="flex w-full overflow-x-scroll gap-4">
                    {value.images !== undefined && iconImages ? (
                      value.images.map((res, i) => (
                        <>
                          <img
                            className="mx-auto h-[100px] w-[100px] border-2 border-black"
                            src={process.env.REACT_APP_UPLOADS + res}
                            alt="uploded-img"s
                          />
                        </>
                      ))
                    ) : (
                      <>
                        {/* {iconImages.map((res, i) => (
                          <>
                            <img
                              className="mx-auto h-[100px] w-[100px] border-2 border-black"
                              src={URL.createObjectURL(iconImages[i])}
                              alt="uploded-img"
                            />
                          </>
                        ))} */}
                      </>
                    )}
                  </div>
                </div>
                <div className="mt-10 text-center">
                  <button
                    type="submit"
                    className="text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      </Modal>
    </>
  );
};

export default Edit;
