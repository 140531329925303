import React, { useEffect, useState } from "react";
import { JOBS } from "../../../helper/endpoints";
import api from "../../../helper/api";
import Button from "../Button/Button";
import {
  CARDBGCOLORS,
  NEWBGLIGHTCOLORS,
  NEWTEXTDARKCOLORS,
  NEWBGDARKCOLORS,
  NEWDARKCOLORS,
} from "../../../helper/constants";
import ApplyNowModel from "../../../pages/Career/ApplyNowModel";
import { useNavigate } from "react-router-dom";

const Card = ({
  sucessSnackBarOpen,
  setSucessSnackBarOpen,
  warningSnackBarOpen,
  setWarningSnackBarOpen,
}) => {
  const [jobCardsData, setJobCardsData] = useState([]);
  const [model, setModel] = useState(false);
  const [myjobtitle, setMyjobtitle] = useState("");

  let index = -1;
  const navigate = useNavigate();
  useEffect(() => {
    api
      .get(`${process.env.REACT_APP_API + JOBS}`)
      .then((res) => setJobCardsData(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  const handleApplyNow = (data) => {
    navigate(`/hiring-developer/${data}`);
  };

  return (
    <>
      {jobCardsData.map((card, i) => {
        index++;
        if (index > CARDBGCOLORS.length - 1) {
          index = 0;
        }
        return (
          <div
            key={i}
            className={`border-2 rounded-[20px] bg-white p-2 lg:p-4`}
            style={{ borderColor: NEWDARKCOLORS[index] }}
          >
            <div className="flex items-center justify-center p-4">
              <div className="w-[100px] h-[100px]">
                <img
                  className="h-full w-full object-contain"
                  src={`${process.env.REACT_APP_UPLOADS + card.job_image}`}
                  alt=""
                />
              </div>
            </div>

            <div className="flex flex-col items-center justify-center flex-1 space-y-2 text-center">
              <h2
                className={`${NEWTEXTDARKCOLORS[index]} text-sm esm:text-base lg:text-lg`}
              >
                {card.job_title}
              </h2>
              <div className="flex gap-2 w-full">
                <div
                  className={`flex items-center justify-center space-x-1 px-3 py-1.5 rounded-[20px] mx-auto w-1/2 ${NEWBGLIGHTCOLORS[index]}`}
                >
                  <svg
                    id="svgexport-7_-_2023-02-09T122907.742"
                    data-name="svgexport-7 - 2023-02-09T122907.742"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.107"
                    height="22.107"
                    viewBox="0 0 22.107 22.107"
                  >
                    <path
                      id="Path_127414"
                      data-name="Path 127414"
                      d="M1.143,0a1.228,1.228,0,0,1,.7.238,1.088,1.088,0,0,1,.445.905A1.143,1.143,0,1,1,1.143,0Z"
                      transform="translate(2.668 1.525)"
                      fill={NEWDARKCOLORS[index]}
                    />
                    <path
                      id="Path_127405"
                      data-name="Path 127405"
                      d="M5.552,45.087a1.9,1.9,0,0,1,1.26-3.325,1.837,1.837,0,0,1,.3.028l.426-.71A3.766,3.766,0,0,0,6.812,41a3.876,3.876,0,0,0-2.305.778,3.8,3.8,0,0,0-.667,5.413A3.057,3.057,0,0,1,5.552,45.087Z"
                      transform="translate(-3 -26.516)"
                      fill={NEWDARKCOLORS[index]}
                    />
                    <path
                      id="Path_127406"
                      data-name="Path 127406"
                      d="M8.144,20.152a1.143,1.143,0,1,0,2.287,0,1.123,1.123,0,0,0-.093-.431L9.719,19.1a1.117,1.117,0,0,0-.431-.093,1.233,1.233,0,0,0-.674.22A1.09,1.09,0,0,0,8.144,20.152ZM9.288,7.574A2.29,2.29,0,0,0,7,9.854a3.669,3.669,0,0,0,3.015.688L9.328,9.4l1.347-1.347a2.255,2.255,0,0,0-1.388-.475Zm11.9-.722.654.654a3.013,3.013,0,0,1,.669-.42,1.906,1.906,0,1,1,2.521,0,3.058,3.058,0,0,1,1.713,2.1,3.829,3.829,0,1,0-6.7-1.651Z"
                      transform="translate(-5.476 -3)"
                      fill={NEWDARKCOLORS[index]}
                    />
                    <path
                      id="Path_127407"
                      data-name="Path 127407"
                      d="M23.771,18.049a3.786,3.786,0,0,0,2.287-.769,2.34,2.34,0,0,0-1.044-1.912A2.184,2.184,0,0,0,23.771,15a2.255,2.255,0,0,0-1.388.475l1.347,1.347-.688,1.147A3.758,3.758,0,0,0,23.771,18.049ZM9.288,29.484A2.29,2.29,0,0,0,7,31.764a3.783,3.783,0,0,0,4.573,0,2.331,2.331,0,0,0-1.4-2.1A2.143,2.143,0,0,0,9.288,29.484Z"
                      transform="translate(-5.476 -10.426)"
                      fill={NEWDARKCOLORS[index]}
                    />
                    <path
                      id="Path_127413"
                      data-name="Path 127413"
                      d="M1.143,0a1.228,1.228,0,0,1,.7.238,1.088,1.088,0,0,1,.445.905A1.143,1.143,0,1,1,1.143,0Z"
                      transform="translate(17.152 1.525)"
                      fill={NEWDARKCOLORS[index]}
                    />
                    <path
                      id="Path_127408"
                      data-name="Path 127408"
                      d="M5.552,7.087a1.906,1.906,0,1,1,2.521,0,3.022,3.022,0,0,1,.669.42L9.4,6.853l1.147.688a3.766,3.766,0,0,0,.081-.729,3.876,3.876,0,0,0-.738-2.252A3.757,3.757,0,0,0,6.812,3,3.807,3.807,0,0,0,3.839,9.191a3.057,3.057,0,0,1,1.713-2.1Zm8.5,2.011a4.947,4.947,0,0,0-3.9,8,4.214,4.214,0,0,1,2.388-2.373,2.668,2.668,0,1,1,3.029,0A4.214,4.214,0,0,1,17.956,17.1a4.926,4.926,0,0,0,1.052-3.042,5.139,5.139,0,0,0-.212-1.437A4.93,4.93,0,0,0,14.053,9.1ZM9.4,21.255l-.717-.718a1.9,1.9,0,0,1-.607,1.034,3.058,3.058,0,0,1,1.713,2.1,3.683,3.683,0,0,0,.758-3.108l-.584.35Zm10.64.316a1.9,1.9,0,0,1-.607-1.034l-.717.718-1.147-.688a3.686,3.686,0,0,0,.758,3.108,3.057,3.057,0,0,1,1.713-2.1Z"
                      transform="translate(-3 -3)"
                      fill={NEWDARKCOLORS[index]}
                    />
                    <path
                      id="Path_127409"
                      data-name="Path 127409"
                      d="M24.163,25.864l1.7-1.7-.622-1.037.112-.194a7.013,7.013,0,0,0,.441-.923,6.08,6.08,0,0,0,.218-.671l.058-.216,1.175-.293V18.42l-1.175-.294-.058-.216a6.61,6.61,0,0,0-.659-1.594l-.112-.194.622-1.037-1.7-1.7L23.125,14l-.194-.112a6.586,6.586,0,0,0-1.594-.659l-.216-.058L20.826,12H18.42l-.294,1.175-.216.058a6.586,6.586,0,0,0-1.594.659L16.121,14l-1.038-.622-1.7,1.7L14,16.121l-.112.194a6.61,6.61,0,0,0-.659,1.594l-.058.216L12,18.42v2.406l1.175.294.058.216a6.61,6.61,0,0,0,.659,1.594l.112.194-.622,1.037,1.7,1.7,1.038-.622.194.112a6.574,6.574,0,0,0,1.594.659l.216.058.293,1.175h2.407l.294-1.175.216-.058a6.589,6.589,0,0,0,1.594-.659l.194-.112Zm-4.54-.524a5.717,5.717,0,1,1,5.717-5.717A5.724,5.724,0,0,1,19.623,25.34Zm7.242,2.287a2.353,2.353,0,0,0-1.583.638,2.22,2.22,0,0,0-.7,1.642,3.783,3.783,0,0,0,4.573,0A2.29,2.29,0,0,0,26.865,27.627Zm-1.143-1.906a1.2,1.2,0,0,0,.409.876,1.064,1.064,0,0,0,.734.268,1.143,1.143,0,0,0,0-2.287,1.121,1.121,0,0,0-.432.093l-.619.619A1.13,1.13,0,0,0,25.721,25.721Z"
                      transform="translate(-8.57 -8.57)"
                      fill={NEWDARKCOLORS[index]}
                    />
                    <path
                      id="Path_127410"
                      data-name="Path 127410"
                      d="M26.627,35a3.44,3.44,0,0,0-3.309,2.534,4.96,4.96,0,0,0,.641.491v-.738h.762v1.142a4.941,4.941,0,0,0,3.812,0V37.287H29.3v.738a4.96,4.96,0,0,0,.641-.491,3.5,3.5,0,0,0-1.619-2.085A3.305,3.305,0,0,0,26.627,35Z"
                      transform="translate(-15.574 -22.803)"
                      fill={NEWDARKCOLORS[index]}
                    />
                    <path
                      id="Path_127412"
                      data-name="Path 127412"
                      d="M1.906,0A1.984,1.984,0,0,1,3.07.4a1.848,1.848,0,0,1,.741,1.509A1.906,1.906,0,1,1,1.906,0Z"
                      transform="translate(9.148 7.623)"
                      fill={NEWDARKCOLORS[index]}
                    />
                    <path
                      id="Path_127411"
                      data-name="Path 127411"
                      d="M49.817,41a3.758,3.758,0,0,0-.728.081l.426.71a1.829,1.829,0,0,1,.3-.028,1.9,1.9,0,0,1,1.26,3.325,3.058,3.058,0,0,1,1.713,2.1A3.807,3.807,0,0,0,49.817,41Z"
                      transform="translate(-31.522 -26.516)"
                      fill={NEWDARKCOLORS[index]}
                    />
                  </svg>
                  <p
                    className={`${NEWTEXTDARKCOLORS[index]} ${NEWBGLIGHTCOLORS[index]} text-sm whitespace-nowrap`}
                  >
                    {card.position} - Position
                  </p>
                </div>
                <div
                  className={`${NEWTEXTDARKCOLORS[index]} ${NEWBGLIGHTCOLORS[index]} space-x-1 px-3 py-1.5 rounded-[20px] mx-auto underline w-1/2`}
                >
                  <h2 className="text-sm">Experience: {card.experience}</h2>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <ul className="space-y-2">
                {card.description.length > 0 &&
                  card.description.map((points, i) => (
                    <li key={i} className="flex gap-2 items-center">
                      <div className="mt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.264"
                          height="15.264"
                          viewBox="0 0 15.264 15.264"
                        >
                          <g
                            id="svgexport-7_-_2023-02-09T145420.671"
                            data-name="svgexport-7 - 2023-02-09T145420.671"
                            transform="translate(0 8.032) rotate(-48)"
                          >
                            <circle
                              id="card_points"
                              fill={NEWDARKCOLORS[index]}
                              data-name="Ellipse 5389"
                              cx="3.166"
                              cy="3.166"
                              r="3.166"
                              transform="matrix(0.707, -0.707, 0.707, 0.707, 0.926, 5.404)"
                            />
                            <path
                              id="card_points"
                              fill={NEWDARKCOLORS[index]}
                              data-name="Path 127418"
                              d="M5.4,0a5.41,5.41,0,0,1,5.4,5.4V10.6a.211.211,0,0,1-.211.211H5.4A5.4,5.4,0,0,1,5.4,0Zm4.982,10.385V5.4A4.982,4.982,0,1,0,5.4,10.385Z"
                            />
                            <path
                              id="card_points"
                              fill={NEWDARKCOLORS[index]}
                              data-name="Ellipse 5390"
                              d="M1.267,0A1.267,1.267,0,1,1,0,1.267,1.268,1.268,0,0,1,1.267,0Zm0,2.111a.844.844,0,1,0-.844-.844A.845.845,0,0,0,1.267,2.111Z"
                              transform="matrix(0.894, -0.447, 0.447, 0.894, 3.705, 4.838)"
                            />
                          </g>
                        </svg>
                      </div>
                      <div
                        className={`${NEWTEXTDARKCOLORS[index]} flex items-center text-sm esm:text-base capitalize`}
                      >
                        {points}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="text-center my-2">
              <button
                onClick={() => handleApplyNow(card.slug)}
                className={`text-white rounded-[10px] px-[20px] py-[10px] ${NEWBGDARKCOLORS[index]}`}
              >
                Apply now
              </button>
            </div>
            {card.can_fresher_apply && (
              <div className="flex items-center justify-center mt-2 space-x-2">
                <svg
                  className="min-w-[21px] min-h-[16px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.749"
                  height="21"
                  viewBox="0 0 19.749 21"
                >
                  <g
                    id="svgexport-7_-_2023-02-09T130908.013"
                    data-name="svgexport-7 - 2023-02-09T130908.013"
                    transform="translate(-15.25 0)"
                  >
                    <path
                      id="card_points"
                      fill={NEWDARKCOLORS[index]}
                      data-name="Path 127415"
                      d="M35.207,9.956V3.537a.6.6,0,0,0-.066-.265.582.582,0,0,0,.066-.2.616.616,0,0,0-.446-.592L26.147.023a.62.62,0,0,0-.338,0L17.2,2.484a.615.615,0,0,0,0,1.184l3.245.927v.728a1.824,1.824,0,0,0,.99,1.639,10.681,10.681,0,0,0,9.094,0,1.824,1.824,0,0,0,.99-1.64V4.595l2.461-.7V9.956a1.843,1.843,0,0,0-1.23,1.732v1.23a.615.615,0,0,0,.615.615h2.461a.615.615,0,0,0,.615-.615v-1.23a1.843,1.843,0,0,0-1.23-1.732Z"
                      transform="translate(-1.438 0)"
                    />
                    <path
                      id="card_points"
                      fill={NEWDARKCOLORS[index]}
                      data-name="Path 127416"
                      d="M29.567,192.066a11.73,11.73,0,0,1-10.179,0,2.843,2.843,0,0,1-.411-.282,6.206,6.206,0,0,0,3.007,5.056s.864.408,1.133.485v0a4.931,4.931,0,0,0,1.359.212,5.034,5.034,0,0,0,1.664-.314c.284-.1.828-.38.828-.38a6.207,6.207,0,0,0,3.009-5.057A2.852,2.852,0,0,1,29.567,192.066Zm-5.089,6.7a6.105,6.105,0,0,1-1.438-.189l1.438,2.157,1.43-2.155A6.106,6.106,0,0,1,24.478,198.764Zm3.541-1.1c-.011.007-.023.012-.034.02l-.517.776c0,.029-2.359,3.557-2.375,3.581v2.879h8a.615.615,0,0,0,.615-.615A6.748,6.748,0,0,0,28.02,197.661Zm1.38,4.795H28.17a.615.615,0,0,1,0-1.23H29.4a.615.615,0,0,1,0,1.23Zm-8.44-4.782-.031-.018A6.753,6.753,0,0,0,15.25,204.3a.615.615,0,0,0,.615.615h8v-2.879C21.075,197.848,21.644,198.7,20.96,197.674Z"
                      transform="translate(0 -183.918)"
                    />
                  </g>
                </svg>
                <p
                  className={`${NEWTEXTDARKCOLORS[index]} leading-none text-text-[12px] esm:text-[14px]`}
                >
                  Fresher with good knowledge can also apply
                </p>
              </div>
            )}
          </div>
        );
      })}
      {/* <ApplyNowModel
      
      sucessSnackBarOpen={sucessSnackBarOpen}
      setSucessSnackBarOpen={setSucessSnackBarOpen}
      warningSnackBarOpen={warningSnackBarOpen}
      setWarningSnackBarOpen={setWarningSnackBarOpen}
      
      model={model} jobtitle={myjobtitle} setModel={setModel} /> */}
    </>
  );
};

export default Card;
