import React from "react";
import "./Button.css";

const Button = (props) => {
  const { text, style, type, backgroundColor, onClick } = props;
  return (
    <button
      type={`${type ? type : "button"}`}
      className={`${
        backgroundColor ? "" : "CustomButton CustomButton-border"
      } ${style}`}
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
