import React from "react";
import LinkButton from "./LinkButton";

const HireDeveloperLinks = () => {
  return (
    <>
      <div className="w-full relative">
        <img
          src="/assets/images/SiteMap/redCircle.svg"
          alt="background"
          className="absolute left-0 top-1/2 -translate-y-1/2 z-[-1] h-full lg:block hidden"
        />
        <div className="sm:mt-20 max-w-[95%] md:max-w-[85%] lg:max-w-[80%] mx-auto">
          <div className="w-full flex sm:flex-row flex-col justify-center sm:py-10 py-5 sm:gap-0 gap-2">
            <div className="sm:w-1/2 w-full flex items-center">
              <div className="sm:rounded-[24px] rounded-[16px] bg-[#CA5183] text-white max-w-[300px] lg:min-w-[250px] lg:w-auto w-full mx-auto">
                <div className="sm:py-[16px] py-[8px] sm:px-[16px] px-[8px] text-center sm:text-[24px] text-[20px] font-semibold">
                  3
                </div>
                <div className="w-full border-[#D5749C] border-t-2 h-[4px] bg-transparent" />
                <div className="sm:py-[16px] py-[8px] sm:px-[16px] px-[8px] text-center sm:text-[24px] text-[20px] font-semibold">
                  Hire Developer
                </div>
              </div>
              <div className="w-full h-full bg-white 2xl:min-h-[300px] min-h-[275px] lg:block hidden">
                <div className="flex w-full h-full">
                  <div className="w-1/2 h-1/2 border-b-2 border-dashed border-[#D5749C]"></div>
                  <div className="w-1/2 h-full">
                    <div className="h-1/2 border-y-2 border-l-2 border-dashed border-[#D5749C] rounded-tl-[16px]"></div>
                    <div className="h-1/2 border-b-2 border-l-2 border-dashed border-[#D5749C] rounded-bl-[16px]"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:w-1/2 w-full relative flex flex-col items-center gap-2">
              <LinkButton
                linkColor={"red"}
                pathName={"/"}
                text={"Node Developer"}
                className={
                  "lg:absolute lg:left-0 xl:top-[-39px] lg:top-[-32.5px]"
                }
              />
              <LinkButton
                linkColor={"red"}
                pathName={"/"}
                text={"HTML Developer"}
                className={
                  "lg:absolute lg:left-0 lg:top-1/2 lg:-translate-y-1/2"
                }
              />
              <LinkButton
                linkColor={"red"}
                pathName={"/"}
                text={"AWS Developer"}
                className={
                  "lg:absolute lg:left-0 xl:bottom-[-39px] lg:bottom-[-32.5px]"
                }
              />
              <LinkButton
                linkColor={"red"}
                pathName={"/"}
                text={"React Developer"}
                className={
                  "lg:absolute lg:right-0 xl:top-[-39px] lg:top-[-32.5px]"
                }
              />
              <LinkButton
                linkColor={"red"}
                pathName={"/"}
                text={"Graphic developer"}
                className={
                  "lg:absolute lg:right-0 lg:top-1/2 lg:-translate-y-1/2"
                }
              />

              <div className="h-1/2 w-full border-t-2 border-b-2 border-dashed border-[#D5749C] lg:block hidden"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HireDeveloperLinks;
