import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const Navigation = () => {
  const { pathname } = useLocation();  
  return (
    <div className="mt-[100px] text-blue w-full h-[72px] bg-[#f3f4fe]">
      <div className="h-full my-auto flex max-w-[80%] mx-auto items-center">
        <Link to="/" className="text-[18px] md:text-[24px]">
          Home
        </Link>
        <KeyboardArrowRightIcon />
        <p className="text-[18px] md:text-[24px] capitalize">
          {pathname.slice(1).replace("/","-")}
        </p>
      </div>
    </div>
  );
};

export default Navigation;
