import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ImageContainer from "../helper/ImageWithHover/ImageContainer";
import { RWebShare } from "react-web-share";

const Project = ({ productData, mycategory }) => {

  const [myproduct1, setMyproduct1] = useState([]);
  const [myproduct2, setMyproduct2] = useState([]);
  const [myproduct3, setMyproduct3] = useState([]);
  const [isImgModal, setIsImgModal] = useState(false);
  const middleIndex = Math.ceil(productData.length / 3);
  const firstHalf = productData.splice(0, middleIndex);
  const secondHalf = productData.splice(-middleIndex);
  const tirdHalf = productData.splice(-middleIndex);


  const categorydata = myproduct1.map((myimg) => {
    const newArray1 = myimg.category && myimg.category.map(category => {
      const matchingObject = mycategory && mycategory.find(obj => obj.Productcategory === category);
      if (matchingObject) {
        return { category, icon: matchingObject.producticon };
      } else {
        return { category };
      }
    });
    return newArray1
  })

  const categorydata2 = myproduct2.map((myimg) => {

    const newArray2 = myimg.category && myimg.category.map(category => {
      const matchingObject = mycategory && mycategory.find(obj => obj.Productcategory === category);
      if (matchingObject) {
        return { category, icon: matchingObject.producticon };
      } else {
        return { category };
      }
    });
    return newArray2
  })

  const categorydata3 = myproduct3.map((myimg) => {

    const newArray3 = myimg.category && myimg.category.map(category => {
      const matchingObject = mycategory && mycategory.find(obj => obj.Productcategory === category);
      if (matchingObject) {
        return { category, icon: matchingObject.producticon };
      } else {
        return { category };
      }
    });
    return newArray3
  })

  useEffect(() => {
    setMyproduct1(firstHalf)
    setMyproduct2(tirdHalf)
    setMyproduct3(secondHalf)
  }, [productData]);

  const [animation, setAnimation] = useState(false);

  var data = []

  for (let i = 0; i < myproduct1.length; i++) {
    data.push(
      <ImageContainer
        setIsImgModal={setIsImgModal}
        setAnimation={setAnimation}
        fullImage={myproduct1[i].Full_image}
        image={`${process.env.REACT_APP_UPLOADS}${myproduct1[i].Display_image.map((e) => e)}`}
        categoryicon={categorydata[i]}
      />
    )
  }

  var data2 = []

  for (let i = 0; i < myproduct2.length; i++) {
    data2.push(
      <ImageContainer
        setIsImgModal={setIsImgModal}
        setAnimation={setAnimation}
        fullImage={myproduct2[i].Full_image}
        image={`${process.env.REACT_APP_UPLOADS}${myproduct2[i].Display_image.map((e) => e)}`}
        categoryicon={categorydata2[i]}
      />
    )
  }

  var data3 = []

  for (let i = 0; i < myproduct3.length; i++) {
    data3.push(
      <ImageContainer
        setIsImgModal={setIsImgModal}
        setAnimation={setAnimation}
        fullImage={myproduct3[i].Full_image}
        image={`${process.env.REACT_APP_UPLOADS}${myproduct3[i].Display_image.map((e) => e)}`}
        categoryicon={categorydata3[i]}
      />
    )
  }


  return (
    <div className={`${isImgModal ? "pause-animation" : `projects ${animation && "hover:pause"}`} w-max`}>
      <div className="w-max" data-aos="fade-left" data-aos-duration="1500">

        <div className="flex gap-x-[20px] w-max cursor-pointer">
          {data.map(e => e)}
        </div>

        <div className="flex gap-x-[20px] ml-[135px] my-[-75px] w-max cursor-pointer">
          {data2.map(e => e)}
        </div>

        <div className="flex gap-x-[20px] w-max cursor-pointer">
          {data3.map(e => e)}
        </div>

      </div>
    </div>
  );
};

export default Project;
