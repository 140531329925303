export const CARDBGCOLORS = [
  "border-[#1d71bb] bg-gradient-to-tl from-white via-[#edf1fe] to-white",
  "border-[#ffdd6a] bg-gradient-to-tl from-white via-[#fef7df] to-white",
  "border-[#ff8984] bg-gradient-to-tl from-white via-[#fee7e6] to-white",
];

export const CARDTEXTCOLORS = [
  "text-[#1d71bb]",
  "text-[#ffc500]",
  "text-[#ff8984]",
];

export const COLORS = ["#1d71bb", "#ffc500", "#ff8984"];

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const YEARS = [
  2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
  2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033,
];

export const SERVICES = [
  "UI/UX Design",
  "App Development",
  "Web Development",
  "Flutter App Development",
  "IOS App Development",
];

export const Requirment = [
  "Support-maintenance",
  "Existing Project",
  "New Project",
  "Hire Dedicated Team",
];

export const CATEGORYICONS = [
  {
    left: "left-[13%]",
    transition: "!transition-all !duration-[0.6s]",
    hover: "group-hover:top-[50%]",
  },
  {
    left: "left-[33%]",
    transition: "!transition-all !duration-[0.8s]",
    hover: "group-hover:top-[50%]",
  },
  {
    left: "left-[53%]",
    transition: "!transition-all !duration-[1.1s]",
    hover: "group-hover:top-[50%]",
  },
  {
    left: "left-[73%]",
    transition: "!transition-all !duration-[1.5s]",
    hover: "group-hover:top-[50%]",
  },
  {
    left: "left-[23%]",
    transition: "!transition-all !duration-[0.69s]",
    hover: "group-hover:top-[65%]",
  },
  {
    left: "left-[43%]",
    transition: "!transition-all !duration-[0.91s]",
    hover: "group-hover:top-[65%]",
  },
  {
    left: "left-[63%]",
    transition: "!transition-all !duration-[1.69s]",
    hover: "group-hover:top-[65%]",
  },
  {
    left: "left-[33%]",
    transition: "!transition-all !duration-[1.09s]",
    hover: "group-hover:top-[80%]",
  },
  {
    left: "left-[53%]",
    transition: "!transition-all !duration-[1.33s]",
    hover: "group-hover:top-[80%]",
  },
];

export const NEWTEXTDARKCOLORS = [
  "text-[#8042C2]",
  "text-[#0E66AD]",
  "text-[#1F6837]",
];

export const NEWBGDARKCOLORS = ["bg-[#8042C2]", "bg-[#0E66AD]", "bg-[#1F6837]"];

export const NEWBGLIGHTCOLORS = [
  "bg-[#F6EDFF]",
  "bg-[#EFF8FF]",
  "bg-[#EFFFF4]",
];

export const NEWTEXTLIGHTCOLORS = [
  "text-[#F6EDFF]",
  "text-[#EFF8FF]",
  "text-[#EFFFF4]",
];
export const NEWLIGHTCOLORS = ["#F6EDFF", "#EFF8FF", "#EFFFF4"];
export const NEWDARKCOLORS = ["#8042C2", "#0E66AD", "#1F6837"];
