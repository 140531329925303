import React, { useEffect, useId } from "react";
import { MONTHS, YEARS } from "../../helper/constants";

const CompnayDetail = ({ values, handleChange, setId, errors, submitCount }) => {
  const id = useId();

  useEffect(() => {
    setId(id);
  }, [id]);

  return (
    <>
      <div className="flex flex-col md:flex-row gap-4 my-4">
        <div className="flex-1">
          <input
            className="w-full lg:flex-1 border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
            placeholder="Company name"
            type="text"
            // name={`${id}-companyName`}
            name="companyName"
            value={values.companyName}
            onChange={handleChange}
          />
          <div className="mt-1">
            <p className="ml-2 text-xs text-red-500">
              {submitCount === 1 && errors.companyName}
            </p>
          </div>
        </div>
        <div className="flex-1">
          <input
            className="w-full lg:flex-1 border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
            placeholder="Current location"
            type="text"
            // name={`${id}companyLocation`}
            name="companyLocation"
            value={values.companyLocation}
            onChange={handleChange}
          />
          <div className="mt-1">
            <p className="ml-2 text-xs text-red-500">
              {submitCount === 1 && errors.companyLocation}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 my-4">
        <div className="flex-1">
          <input
            className="w-full lg:flex-1 border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
            placeholder="Compnay webstite"
            type="text"
            // name={`${id}compnayWebsite`}
            name="compnayWebsite"
            value={values.compnayWebsite}
            onChange={handleChange}
          />
          <div className="mt-1">
            <p className="ml-2 text-xs text-red-500">
              {submitCount === 1 && errors.compnayWebsite}
            </p>
          </div>
        </div>
        <div className="flex-1">
          <input
            className="w-full lg:flex-1 border border-[#34a0ff] text-gray-400 placeholder:text-gray-400 pl-3 py-1 rounded-md"
            placeholder="Working as"
            type="text"
            // name={`${id}workingAs`}
            name="workingAs"
            value={values.workingAs}
            onChange={handleChange}
          />
          <div className="mt-1">
            <p className="ml-2 text-xs text-red-500">
              {submitCount === 1 && errors.workingAs}
            </p>
          </div>
        </div>
      </div>
      <div>
        <h5 className="text-blue font-bold mb-2">Working Time Period</h5>
        <div className="flex flex-col gap-4 md:flex-row items-center">
          <div className="space-x-2">
            <select
              className="p-1 text-blue border border-[#34a0ff] rounded-[4px]"
              // name={`${id}startmonth`}
              name="startmonth"
              onChange={handleChange}
            >
              {MONTHS.map((month, index) => (
                <option key={index}>{month}</option>
              ))}
            </select>
            <select
              className="p-1 text-blue border border-[#34a0ff] rounded-[4px]"
              // name={`${id}startyear`}
              name="startyear"
              onChange={handleChange}
            >
              {YEARS.map((month, index) => (
                <option key={index}>{month}</option>
              ))}
            </select>
          </div>
          <span className="text-blue mx-10">To</span>
          <div className="space-x-2">
            <select
              className="p-1 text-blue border border-[#34a0ff] rounded-[4px]"
              // name={`${id}endmonth`}
              name="endmonth"
              onChange={handleChange}
            >
              {MONTHS.map((month, index) => (
                <option key={index}>{month}</option>
              ))}
            </select>
            <select
              className="p-1 text-blue border border-[#34a0ff] rounded-[4px]"
              // name={`${id}endyear`}
              name="endyear"
              onChange={handleChange}
            >
              {YEARS.map((month, index) => (
                <option key={index}>{month}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompnayDetail;
