import * as yup from "yup";

export default yup.object().shape({
  first_name: yup
    .string()
    .required("first Name is required !")
    .matches(/^[a-zA-Z]+$/, "first Name should contain only letters"),
  last_name: yup
    .string()
    .required("Last Name is required !")
    .matches(/^[a-zA-Z]+$/, "Last Name should contain only letters"),
  email: yup.string().email().required("Email is required !"),
  mobile_number: yup
    .string()
    .min(2, "Minimum 2 number are required")
    .max(12, "Maximum 12 number are allowed")
    .required("Mobile Number is required !"),
  // service: yup.string().required("Service item is required"),
  budget: yup.string().required("budget is required !"),
  how_soon_you_want_to_start: yup
    .string()
    .required("how soon you want to start is required !"),
  // requirement: yup.string().required("requirements is required !"),
  project_description: yup
    .string()
    .required("project description is required !"),
  files: yup.string().required("files is required !"),
  project_description: yup
    .string()
    .required("project description is required !"),
});
