import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { IconButton, Modal } from "@mui/material";
import Warning from "../../../components/SnackBars/SnackBar";
import api from "../../../helper/api";
import { PRODUCT, PRODUCTCATEGORY } from "../../../helper/endpoints";
import CloseIcon from "@mui/icons-material/Close";
import SucessSnackBar from "../../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../../components/SnackBars/SnackBar";
import usePreview from "../usePreview";

const Add = ({ model, setModel, getContact }) => {

  const [categorydata, setCategorydata] = useState([]);
  const [open, setOpen] = useState(false);
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [myimage, setMyimage] = useState(null);
  const uploadedImg = usePreview(myimage);

  const initialValues = {

    category: [],
    Full_image: [],
    Display_image: "",

  };

  useEffect(() => {
    api
      .get(`${process.env.REACT_APP_API + PRODUCTCATEGORY}`)
      .then((res) => {
        setCategorydata(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [model]);

  const handelcheckbox = (e) => {
    var arrayids = [];
    document.getElementsByName("ids[]").forEach((e) => {
      if (e.checked) {
        arrayids.push(e.value);
      }
    });
    //errors.requirement = arrayids
    values.category = arrayids;
  };

  const { values, handleSubmit, handleChange, setFieldValue, resetForm } =
    useFormik({
      initialValues,
      onSubmit: (value) => {

        if (value.category.length !== 0) {
          try {
            const formData = new FormData();
            if (
              value.category.length == 0 &&
              value.Full_image.length == 0 &&
              (!value.Display_image)
            ) {
              setWarningSnackBarOpen(!warningSnackBarOpen);
            } else {
              formData.append("category", JSON.stringify(value.category));

              for (let i = 0; i < value.Full_image.length; i++) {
                formData.append("Full_image", value.Full_image[i], value.Full_image[i].name);
              }
              formData.append(
                "Display_image",
                value.Display_image,
                value.Display_image.name
              );
              api
                .postWithToken(`${process.env.REACT_APP_API + PRODUCT}`, formData)
                .then((res) => {
                  getContact();
                  resetForm();
                  setMyimage(null);
                  setSucessSnackBarOpen(!sucessSnackBarOpen);
                  setModel(!model);
                })
                .catch((err) => setWarningSnackBarOpen(!warningSnackBarOpen));
            }
          } catch {
            setWarningSnackBarOpen(!warningSnackBarOpen);
          }
        }
        value.category.length === 0 && setWarningSnackBarOpen(!warningSnackBarOpen);
      }
    });

  const handleClose = () => {
    setModel(false);
    resetForm();
    setFieldValue(" ");
    setMyimage(null);
    //setCategorydata("");
  };

  return (
    <>
      <SucessSnackBar
        open={sucessSnackBarOpen}
        setOpen={setSucessSnackBarOpen}
        text="Card Added Successfully !"
        type="success"
      />

      <ErrorSnackBar
        open={warningSnackBarOpen}
        setOpen={setWarningSnackBarOpen}
        text={"Something Missing !"}
        type="error"
      />

      <Modal
        open={model}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-descriptionArray"
        style={{ overflow: "scroll" }}
      >
        <form onSubmit={handleSubmit} encType="encType='multipart/form-data'">
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-3xl p-5 md:p-10 w-[90%] md:w-[600px]">
            <div className="absolute top-2 right-2">
              <IconButton onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="mt-5">

              <label
                htmlFor="countries"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Category
              </label>

              <div className="grid grid-cols-2 md:grid-cols-4 items-center gap-3" >
                {categorydata.map((data, index) =>
                  <div key={index} className="flex">
                    <input type="checkbox" name="ids[]"
                      onChange={(e) => handelcheckbox(e)}
                      id={data.Productcategory}
                      value={data.Productcategory}
                    />
                    <label
                      htmlFor={data.Productcategory}
                      className=" p-1 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {data.Productcategory}
                    </label>
                  </div>
                )}
              </div>

            </div>
            <div className="mt-5">
              <label
                htmlFor="Full_image"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Full image
              </label>
              <input
                multiple
                type="file"
                id="Full_image"
                name="Full_image"
                onChange={(e) => (
                  setFieldValue("Full_image", e.target.files)
                )}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />

              {/* { values?.Full_image && values?.Full_image.map((myimg, i) => {
                return (
                  <div key={i}>
                    <p>{myimg.name}</p>
                  </div>

                );
              })} */}

            </div>
            <div className="mt-5">
              <label
                htmlFor="Display_image"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Display image
              </label>
              <input
                type="file"
                id="Display_image"
                name="Display_image"
                onChange={(e) => (
                  setFieldValue("Display_image", e.target.files[0]),
                  setMyimage(e)
                )}

                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

              />

              {uploadedImg[0]?.Display_image && (
                <img
                  className="text-left mb-1 w-[100px] h-[100px]"
                  src={uploadedImg[0]?.Display_image}
                />
              )}
            </div>

            <div className="mt-10 text-center">
              <button
                type="submit"
                className=" text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Modal>
      <Warning
        open={open}
        setOpen={setOpen}
        text="This value is already present !"
        type="warning"
      />

    </>
  );
};

export default Add;