import * as yup from "yup";

export default yup.object().shape({
    firstName: yup.string().required("firstName is required !").matches(/^[a-zA-Z]+$/, 'firstName should contain only letters'),
    lastName: yup.string().required("lastName is required !").matches(/^[a-zA-Z]+$/, 'lastName should contain only letters'),
    email: yup.string().email().required("email is required !"),
    phone: yup.string().min(2, 'Minimum 2 number are required').max(12, 'Maximum 12 number are allowed').required("phone is required !"),
    location: yup.string().required("location is required !"),
    SchoolInstitute: yup.string().required("SchoolInstitute is required !"),
    SchoolDegree: yup.string().required("SchoolDegree is required !"),
    SchoolLocation: yup.string().required("SchoolLocation is required !"),
    images: yup.string().required("images is required!"),
    totalExperience: yup.string().required("totalExperience is required !"),
    startmonth: yup.string().required("startmonth is required !"),
    startyear: yup.string().required("startyear is required !"),
    endmonth: yup.string().required("endmonth is required !"),
    endyear: yup.string().required("endyear is required !"),
    companyName: yup.string().required("Companyname is required !"),
    companyLocation: yup.string().required("Companylocation is required !"),
    compnayWebsite: yup.string().url('Invalid URL').required('Compnaywebsite is required !'),
    workingAs: yup.string().required("Workingas is required !"),
    "reson-for-job-change": yup.string().required("reson for job change is required !"),
    "last-date-of-current-job": yup.string().required("last date of current job is required !"),
});

