import React from "react";
import { Link, useLocation } from "react-router-dom";
import DrawerComp from "../Drawer/Drawer";
import Button from "../helper/Button/Button";

const Navbar = () => {
  const { pathname } = useLocation();

  return (
    <div className="fixed w-full bg-white z-[999] top-0">
      <div className="relative w-full h-[76px] shadow-xl">
        <img
          className="-z-50 hidden h-[76px] lg:block w-full top-0 absolute"
          src="/assets/images/home/navbar.png"
          alt=""
        />
        <div className="h-full flex items-center justify-between max-w-[95%] md:max-w-[85%] lg:max-w-[80%] mx-auto">
          <img
            className="w-[150px]"
            src="/assets/images/home/smart.png"
            alt=""
          />
          <div className="w-full ">
            <ul className="hidden list-none lg:flex lg:justify-end">
              <li
                className={`${
                  pathname === "/" ? "text-blue" : "text-black"
                } mr-5`}
              >
                <Link
                  to="/"
                  className={`navlinks ${
                    pathname === "/" && "navlinks-active"
                  }`}
                >
                  Home
                </Link>
              </li>
              <li
                className={`${
                  pathname === "/services"
                    ? "text-blue underline-offset-4"
                    : "text-black"
                } mr-5`}
              >
                <Link
                  to="/services"
                  className={`navlinks ${
                    pathname === "/services" && "navlinks-active"
                  }`}
                >
                  Services
                </Link>
              </li>
              <li
                className={`${
                  pathname === "/about-us" ? "text-blue" : "text-black"
                } mr-5`}
              >
                <Link
                  to="/about-us"
                  className={`navlinks ${
                    pathname === "/about-us" && "navlinks-active"
                  }`}
                >
                  About Us
                </Link>
              </li>
              <li
                className={`${
                  pathname === "/portfolio"
                    ? "text-blue  underline-offset-4"
                    : "text-black"
                } mr-5`}
              >
                <Link
                  to="/portfolio"
                  className={`navlinks ${
                    pathname === "/portfolio" && "navlinks-active"
                  }`}
                >
                  Portfolio
                </Link>
              </li>
              <li
                className={`${
                  pathname === "/career" ? "text-blue" : "text-black"
                } mr-5`}
              >
                <Link
                  to="/career"
                  className={`navlinks ${
                    pathname === "/career" && "navlinks-active"
                  }`}
                >
                  Career
                </Link>
              </li>
              <li
                className={`${
                  pathname === "/contact-us" ? "text-blue" : "text-black"
                } mr-5`}
              >
                <Link
                  to="/contact-us"
                  className={`navlinks ${
                    pathname === "/contact-us" && "navlinks-active"
                  }`}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/request-for-quote">
                  <Button
                    text="Request For Quote"
                    style="bg-blue rounded-[8px] text-white px-2"
                  />
                </Link>
              </li>
            </ul>
            <div className="block float-right lg:hidden">
              <DrawerComp />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
