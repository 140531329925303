import React, { useEffect, useState } from "react";
import ProtfolioOfer from "../../components/Portfolio/ProtfolioOfer";
import Project from "../../components/Portfolio/Project";
import Heading from "../../components/helper/Heading";
import RouteAnimation from "../../components/helper/RouteAnimation";
import { Link } from "react-router-dom";
import ContactUs from "../../components/ContactUs/ContactUs";
import SucessSnackBar from "../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../components/SnackBars/SnackBar";
import api from "../../helper/api";
import { PRODUCTCATEGORY } from "../../helper/endpoints";

const Portfolio = () => {
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);

  const [productData, setProductData] = useState([]);
  const [checkcomp, setCheckcomp] = useState("All");
  const [category, setcategory] = useState([]);

  const getPortfolio = async (data) => {
    api
      .get(
        `${process.env.REACT_APP_API + "/thisportfolio"}?protfolioname=${data}`
      )
      .then((res) => {
        // setTotalPage(res.data.totalPages);
        setProductData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getCategory = async () => {
    api
      .get(`${process.env.REACT_APP_API + PRODUCTCATEGORY}`)
      .then((res) => {
        setcategory(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPortfolio();
    getCategory();
  }, []);

  const HireNowButton = (
    <Link
      className="CustomButton CustomButton-border mt-5 bg-blue text-white px-5 py-3 rounded-lg"
      to="/request-for-quote"
    >
      Hire Now
    </Link>
  );

  return (
    <>
      <SucessSnackBar
        open={sucessSnackBarOpen}
        setOpen={setSucessSnackBarOpen}
        text="Thank you for contacting us !"
        type="success"
      />

      <ErrorSnackBar
        open={warningSnackBarOpen}
        text="Something Missing !"
        type="error"
      />

      <RouteAnimation>
        <div className="overflow-hidden">
          <div className="mt-10 max-w-[95%] md:max-w-[85%] mx-auto">
            <Heading
              text="Projects"
              image="/assets/images/Career/Connect.svg"
              styles="absolute w-full top-12 text-center mx-auto text-[34px] text-blue"
            />
          </div>

          <div className="flex flex-col md:flex-row mt-10 max-w-[95%] md:max-w-[85%] mx-auto justify-center">
            <div className="w-full flex justify-between">
              <p
                onClick={() => (getPortfolio(), setCheckcomp("All"))}
                className={
                  checkcomp === "All"
                    ? "navlinks navlinks-active cursor-pointer w-full  text-center mx-auto  leading-6 esm:leading-8 text-[21px]  md:text-[28px] lg:text-[34px] text-blue"
                    : "navlinks  cursor-pointer w-full  text-center mx-auto  leading-6 esm:leading-8 text-[21px]  md:text-[28px] lg:text-[34px] text-blue"
                }
              >
                All
              </p>
              {category.map((data, i) => (
                <p
                  onClick={() => (
                    getPortfolio(data.Productcategory),
                    setCheckcomp(data.Productcategory)
                  )}
                  className={
                    checkcomp === data.Productcategory
                      ? "navlinks capitalize whitespace-nowrap navlinks-active cursor-pointer w-full text-center mx-auto leading-6 esm:leading-8 text-[21px] md:text-[28px] lg:text-[34px] text-blue"
                      : "navlinks capitalize whitespace-nowrap cursor-pointer w-full  text-center mx-auto  leading-6 esm:leading-8 text-[21px] md:text-[28px] lg:text-[34px] text-blue"
                  }
                >
                  {data.Productcategory}
                </p>
              ))}
            </div>
          </div>

          <div className="mt-10">
            <Project productData={productData} mycategory={category} />
          </div>

          <div className="mt-10 bg-[#f2f3fe] grid gap-5 grid-cols-1 lg:grid-cols-2">
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              className="grid grid-cols-1 md:grid-cols-2 items-center"
            >
              <img src="/assets/images/Portfolio/paper.png" alt="" />
              <div className="ml-10">
                <p className="text-[22px] font-semibold">
                  We've got an entire team ready for a futuristic marketplace!
                </p>
                <p className="my-3 opacity-80">
                  Learn more about your soon-to-become technology.
                </p>
                {HireNowButton}
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1500"
              className="grid grid-cols-1 md:grid-cols-2 items-center"
            >
              <img src="/assets/images/Portfolio/laptop.png" alt="" />
              <div className="ml-10">
                <p className="text-[22px] font-semibold">
                  Your idea is just one discussion away from your customers!
                </p>
                <p className="my-3">
                  Get in touch with our project management team now to discuss
                  your ventures thoroughly.
                </p>
                {HireNowButton}
              </div>
            </div>
          </div>
          <div className="mt-10 max-w-[95%] md:max-w-[85%] mx-auto">
            <ProtfolioOfer />
          </div>
          <img
            className="-z-[999] absolute top-[70%] right-[35%]"
            src="/assets/images/servicesNew/YellowCircle.svg"
            alt=""
          />
          <div
            data-aos="zoom-out"
            data-aos-duration="1500"
            className="bg-[#f2f3fe] mt-10 py-6"
          >
            <div className=" max-w-[95%] md:max-w-[85%] mx-auto relative">
              <div className="grid gap-5 grid-cols-1 lg:grid-cols-2">
                <div>
                  <div className="flex">
                    <div className="relative">
                      <img
                        className=""
                        src="/assets/images/Career/Connect.svg"
                        alt=""
                      />
                      <p className="absolute min-w-[75%] top-12 text-[34px] text-blue m-auto left-1/2 -translate-x-1/2">
                        Connect with us!
                      </p>
                    </div>
                  </div>
                  <h3 className="my-4 text-blue text-[22px] font-medium">
                    To make requests for further information, contact us via the
                    form provided
                  </h3>
                  <p className="text-[18px] opacity-80">We are always available! you will hear from us shortly.</p>
                </div>
                <ContactUs
                  sucessSnackBarOpen={sucessSnackBarOpen}
                  setSucessSnackBarOpen={setSucessSnackBarOpen}
                  warningSnackBarOpen={warningSnackBarOpen}
                  setWarningSnackBarOpen={setWarningSnackBarOpen}
                />
              </div>
            </div>
          </div>
        </div>
      </RouteAnimation>
    </>
  );
};

export default Portfolio;
