import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./components/helper/ScrollToTop";
import ScrollTop from "./components/ScrollTop/ScrollTop";
import Navbar from "./components/Navbar/Navbar";
import Navigation from "./components/PageNavigation/Navigation";
import route from "./helper/route";
import adminRoute from "./helper/adminRoute";
import Footer from "./components/Footer/Footer";
// import Footer from "./components/Footer/FooterNew";
import AdminRoute from "./ProtectedRoutes/AdminRoute";
import Chat from "./components/ChatButton/Chat";
import { Oval } from "react-loader-spinner";
import NewFooter from "./components/Footer/NewFooter";

function App() {
  const { pathname } = useLocation();
  const [isLoad, setIsLoad] = useState(false);
  const [GIFpath, setGIFpath] = useState("");

  const [loadingProgress, setLoadingProgress] = useState(0);

  // useEffect(() => {
  //   const updateLoadingProgress = (event) => {
  //     if (event.lengthComputable) {
  //       const progress = (event.loaded / event.total) * 100;
  //       setLoadingProgress(progress);
  //     }
  //   };

  //   const handleLoadStart = () => {
  //     setLoadingProgress(0);
  //   };

  //   const handleLoadEnd = () => {
  //     setLoadingProgress(100);
  //   };

  //   window.addEventListener("loadstart", handleLoadStart);
  //   window.addEventListener("progress", updateLoadingProgress);
  //   window.addEventListener("load", handleLoadEnd);

  //   // Temizleme işlemi
  //   return () => {
  //     window.removeEventListener("loadstart", handleLoadStart);
  //     window.removeEventListener("progress", updateLoadingProgress);
  //     window.removeEventListener("load", handleLoadEnd);
  //   };
  // }, []);

  useEffect(() => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", "http://localhost:3000/", true);
  
    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        setLoadingProgress(progress);
      }
    };
  
    xhr.onloadstart = () => {
      setLoadingProgress(0);
    };
  
    xhr.onloadend = () => {
      setLoadingProgress(100);
    };
  
    xhr.send();
  
    // Clean up function
    return () => {
      xhr.abort();
    };
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    setGIFpath("/assets/images/smarttechnica_loader.gif");
    const delay = setTimeout(() => {
      setIsLoad(true);
      // setGIFpath("");
    }, 1969);
    return () => clearInterval(delay);
  }, []);
  useEffect(() => {
    console.log(loadingProgress)
  }, [loadingProgress])

  return (
    <>
      {loadingProgress === 100 ? (
        <div className="overflow-hidden font-notoSans">
          <ScrollTop />
          {/* <Chat /> */}
          <ScrollToTop />
          {!pathname.startsWith("/admin") && (
            <>
              {pathname !== "/" && pathname !== "/site-map" && <Navigation />}
              <Navbar />
            </>
          )}
          <Routes>
            {route.map((route, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={route.path}
                  element={route.element}
                />
              );
            })}
            {adminRoute.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    route.path === "/admin-login" ? (
                      route.element
                    ) : (
                      <AdminRoute>{route.element}</AdminRoute>
                    )
                  }
                />
              );
            })}
          </Routes>
          {/* {!pathname.startsWith("/admin") && <Footer />} */}
          {!pathname.startsWith("/admin") && <NewFooter />}
        </div>
      ) : (
        <>
          <div className="h-screen relative w-full max-w-[200px] mx-auto flex justify-center items-center">
            <Oval
              height={150}
              width={150}
              color="#1b75bb"
              wrapperStyle={{}}
              visible={true}
              ariaLabel="this is heading"
              secondaryColor="#d5d7e9"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
            <div className="absolute h-[100px] w-[100px] overflow-hidden">
              <img
                src="/assets/images/home/smartLogo.png"
                alt="logo"
                className="h-full w-full"
              />
            </div>
          </div>
          {/* <div className="h-screen relative w-full max-w-[200px] mx-auto flex justify-center items-center">{loadingProgress}%</div> */}
        </>
      )}
    </>
  );
}

export default App;
