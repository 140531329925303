import React from "react";
import Hire from "../../components/QuoteForm/Quote";
import SucessSnackBar from "../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../components/SnackBars/SnackBar";
import { useState } from "react";

const RequestForQuote = () => {
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);

  return (
    <div className="mt-10 lg:mt-20">
      <SucessSnackBar
        open={sucessSnackBarOpen}
        setOpen={setSucessSnackBarOpen}
        text="Thank you for contacting us !"
        type="success"
      />
      <ErrorSnackBar
        open={warningSnackBarOpen}
        text="Something Missing !"
        type="error"
      />

      <Hire
        sucessSnackBarOpen={sucessSnackBarOpen}
        setSucessSnackBarOpen={setSucessSnackBarOpen}
        warningSnackBarOpen={warningSnackBarOpen}
        setWarningSnackBarOpen={setWarningSnackBarOpen}
      />
    </div>
  );
};

export default RequestForQuote;
