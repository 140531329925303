import React from "react";
import LinkButton from "./LinkButton";

const ServicesLink = () => {
  return (
    <>
      <div className="w-full relative">
        <img
          src="/assets/images/SiteMap/GreenCircle.svg"
          alt="background"
          className="absolute right-0 top-1/2 -translate-y-1/2 z-[-1] h-full lg:block hidden"
        />
        <div className="sm:mt-20 max-w-[95%] md:max-w-[85%] lg:max-w-[80%] mx-auto">
          <div className="w-full flex sm:flex-row flex-col-reverse justify-center sm:py-10 py-5 sm:gap-0 gap-2">
            <div className="sm:w-1/2 w-full relative flex flex-col items-center gap-2">
              <LinkButton
                linkColor={"green"}
                pathName={"/services/app-development"}
                text={"App Development"}
                className={
                  "lg:absolute lg:right-0 xl:top-[-39px] lg:top-[-32.5px]"
                }
              />
              <LinkButton
                linkColor={"green"}
                pathName={"/services/uiux-design"}
                text={"UI/UX Design"}
                className={
                  "lg:absolute lg:right-0 lg:top-1/2 lg:-translate-y-1/2"
                }
              />
              <LinkButton
                linkColor={"green"}
                pathName={"/services/devops"}
                text={"Devops"}
                className={
                  "lg:absolute lg:right-0 xl:bottom-[-39px] lg:bottom-[-32.5px]"
                }
              />
              <LinkButton
                linkColor={"green"}
                pathName={"/services/web-development"}
                text={"Web Development"}
                className={
                  "lg:absolute lg:left-0 xl:top-[-39px] lg:top-[-32.5px]"
                }
              />
              <LinkButton
                linkColor={"green"}
                pathName={"/services/web-development"}
                text={"Web Design"}
                className={
                  "lg:absolute lg:left-0 lg:top-1/2 lg:-translate-y-1/2"
                }
              />

              <div className="h-1/2 w-full border-t-2 border-b-2 border-dashed border-[#2B747E] lg:block hidden"></div>
            </div>
            <div className="sm:w-1/2 w-full flex items-center">
              <div className="w-full h-full bg-white 2xl:min-h-[300px] min-h-[275px] lg:block hidden">
                <div className="flex w-full h-full">
                  <div className="w-1/2 h-full">
                    <div className="h-1/2 border-y-2 border-r-2 border-dashed border-[#2B747E] rounded-tr-[16px]"></div>
                    <div className="h-1/2 border-b-2 border-r-2 border-dashed border-[#2B747E] rounded-br-[16px]"></div>
                  </div>
                  <div className="w-1/2 h-1/2 border-b-2 border-dashed border-[#2B747E]"></div>
                </div>
              </div>
              <div className="sm:rounded-[24px] rounded-[16px] bg-[#2B747E] text-white max-w-[300px] lg:min-w-[250px] lg:w-auto w-full mx-auto">
                <div className="sm:py-[16px] py-[8px] sm:px-[16px] px-[8px] text-center sm:text-[24px] text-[20px] font-semibold">
                  2
                </div>
                <div className="w-full border-[#559098] border-t-2 h-[4px] bg-transparent" />
                <div className="sm:py-[16px] py-[8px] sm:px-[16px] px-[8px] text-center sm:text-[24px] text-[20px] font-semibold">
                  Service
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesLink;
