import React, { useEffect, useState } from "react";
import Header from "../../../components/AdminPanelComponents/Header";
import Sidebar from "../../../components/AdminPanelComponents/Slidebar";
import { IconButton, Pagination, Button } from "@mui/material";
import { Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../../helper/api";
import { PRODUCT } from "../../../helper/endpoints";
import { PRODUCTCATEGORY } from "../../../helper/endpoints";
import EditIcon from "@mui/icons-material/Edit";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Add from "./Add";
import Edit from "./Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SucessSnackBar from "../../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../../components/SnackBars/SnackBar";

const PortfolioManagement = () => {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [model, setModel] = useState(false);
  const [editmodel, setEditmodel] = useState(false);
  const [myedit, setMyedit] = useState("");
  const [modeldata, setModeldata] = useState([]);
  const [category, setCategory] = useState("");
  const [categorylist, setCategorylist] = useState([]);
  const [myproducticon, setMyproducticon] = useState("");
  const [open, setOpen] = useState(false);
  const [iseditB, setIseditB] = useState("");
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [priviewicon, setPriviewicon] = useState("");


  const getContact = async () => {
    api
      .get(`${process.env.REACT_APP_API + PRODUCT}?page=${page}`)
      .then((res) => {
        setTotalPage(res.data.totalPages);
        setProductData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const addcategory = () => {

    try {

      if (category && myproducticon) {
        const formData = new FormData();
        formData.append("Productcategory", category);
        formData.append("producticon", myproducticon.producticon, myproducticon.producticon.name);
        api
          .postWithToken(
            `${process.env.REACT_APP_API + PRODUCTCATEGORY}`,
            formData
          ).then((res) => {
            setModeldata("");
            setCategory("");
            setIseditB("");
            setCategory(" ");
            document.getElementById('producticon').value = null;
            setMyproducticon("")
            setPriviewicon("")
            setSucessSnackBarOpen(!sucessSnackBarOpen)
            getallcategory()
          })
          .catch((err) => setWarningSnackBarOpen(!warningSnackBarOpen));
      } else {
        setWarningSnackBarOpen(!warningSnackBarOpen);
      }

    } catch {
      setWarningSnackBarOpen(!warningSnackBarOpen);
    }

  };

  const Editcategory = (id) => {

    try {

      if ((category) || (myproducticon)) {


        const formData = new FormData();
        formData.append("Productcategory", category);
     
     if(myproducticon){
      formData.append("producticon", myproducticon.producticon, myproducticon.producticon.name);
     }


        api
          .putWithToken(
            `${process.env.REACT_APP_API + PRODUCTCATEGORY}/${id}`,
            formData
          ).then((res) => {
            setModeldata("");
            setCategory("");
            setIseditB("");
            setCategory(" ");
            document.getElementById('producticon').value = null;
            setMyproducticon("")
            setPriviewicon("")
            setSucessSnackBarOpen(!sucessSnackBarOpen)
            getallcategory()
          })
          .catch((err) => setWarningSnackBarOpen(!warningSnackBarOpen));
      } else {
        setWarningSnackBarOpen(!warningSnackBarOpen);
      }

    } catch {
      setWarningSnackBarOpen(!warningSnackBarOpen);
    }
  };

  const getallcategory = () => {
    api
      .get(`${process.env.REACT_APP_API + PRODUCTCATEGORY}`)
      .then((res) => {
        setCategorylist(res.data.data)
      })
      .catch((err) => console.log(err));
  };

  const handleOpen = (id) => {
    setOpen(true);
    getallcategory()
  };

  const handleClose = () => {
    setOpen(false);
    setModeldata("");
    setCategory("");
    setIseditB("");
    setCategory(" ");
    document.getElementById('producticon').value = null;
    setMyproducticon("")
    setPriviewicon("")
  };

  const handelDelete = (id) => {

    api.deleteWithToken(`${process.env.REACT_APP_API + PRODUCT}/${id}`).then((res) => {
      if (productData.length === 1) {
        setPage(page - 1)
      }
      getContact();
    });
  };

  const deletecategory = (id) => {
    api.deleteWithToken(`${process.env.REACT_APP_API + PRODUCTCATEGORY}/${id}`).then((res) => {
      getallcategory();
    });
  };

  const handelEdit = (id) => {
    setMyedit(...productData.filter((e) => e._id === id));
    setEditmodel(true);
  };

  useEffect(() => {
    getContact();
  }, [page]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className=" bg-white container mx-auto">
          <div className="p-5 w-full">
            <header className="flex justify-between relative py-4 border-b border-slate-100">
              <h2 className="text-sm  font-semibold text-slate-800">
                All Portfolios
              </h2>

              <div className="flex gap-1 ">
                <div>
                  <Button
                    onClick={() => handleOpen(!open)}
                    variant="contained"
                    sx={{ color: "white" }}
                  >
                    Add category
                  </Button>
                </div>

                <div>
                  <Button
                    onClick={() => setModel(!model)}
                    variant="contained"
                    sx={{ color: "white" }}
                  >
                    Add Portfolio
                  </Button>
                </div>
              </div>

              <Add model={model} setModel={setModel} getContact={getContact} />

              <Edit
                setModeldata={setModeldata}
                setMyedit={setMyedit}
                editmodel={editmodel}
                editdata={myedit}
                setEditmodel={setEditmodel}
                getContact={getContact}
                getcategory={category}
              />
            </header>

            <div className="overflow-x-auto">
              <table className=" w-full table-auto">
                <thead className=" font-semibold uppercase text-slate-400 bg-slate-50">
                  <tr>
                    <th className=" p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Category
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Full Image
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Display Image
                      </div>
                    </th>

                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                {productData?.map((data) => (
                  <tbody key={data._id}>
                    <tr className="border-b-2 hover:bg-slate-50">
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {data.category && data.category?.join(",")}
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">

                        {data.Full_image.map((e, i) => (
                          <div key={i} className="text-[20px]  text-blue">
                            <img
                              className="text-left mb-1 w-[100px] h-[100px]"
                              src={
                                `${process.env.REACT_APP_UPLOADS}` + e
                              }
                            />
                          </div>
                        ))}

                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px]  text-blue">
                          <img
                            className="text-left mb-1 w-[100px] h-[100px]"
                            src={
                              `${process.env.REACT_APP_UPLOADS}` +
                              data.Display_image
                            }
                          />
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[#1e293b]">
                          <IconButton
                            onClick={() => {
                              handelDelete(data._id);
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handelEdit(data._id);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>

          <nav className="p-3" aria-label="Page navigation example">
            <Stack spacing={2}>
              <Pagination
                count={totalPage}
                page={page}
                pages={totalPage}
                onChange={(e, value) => setPage(value)}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </nav>
        </div>
      </div>


      <SucessSnackBar
        open={sucessSnackBarOpen}
        setOpen={setSucessSnackBarOpen}
        text="Category Added Successfully !"
        type="success"
      />
      <ErrorSnackBar
        open={warningSnackBarOpen}
        setOpen={setWarningSnackBarOpen}
        text="Something Missing !"
        type="error"
      />

      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-descriptionArray"
        style={{ overflow: "scroll" }}
      >
        <form  className="h-full"  encType="encType='multipart/form-data'">

          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-3xl p-5 md:p-10 w-[90%] overflow-y-scroll  md:w-[700px] h-[90%]">
            <div className="absolute top-2 right-2">
              <IconButton onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </div>

            {!iseditB && (
              <div className="mt-5">
                <label
                  htmlFor="Productcategory"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Category name
                </label>

                <div className="flex gap-5 justify-between">
                  <div>
                    <input
                      type="text"
                      id="Productcategory"
                      name="Productcategory"
                      onChange={(e) => setCategory(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter category name"
                      value={category}
                    />
                  </div>

                  <div className="">
                  <input
                    type="file"
                    id="producticon"
                    name="producticon"
                    onChange={(e) => setMyproducticon({ [e.target.name]: e.target.files[0] }, setPriviewicon(e.target.files[0]))}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {priviewicon && <img className="mt-1" src={URL.createObjectURL(priviewicon)} />}


                </div>

                </div>
                <div className="  mt-5 text-left">

                    <button
                      type="button"
                      onClick={addcategory}
                      className=" text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Add
                    </button>
                  </div>
              </div>
            )}
            <div className="mt-8">
              {categorylist &&
                categorylist?.map((data, index) => {
                  return (
                    <div
                      className=" grid grid-cols-3 justify-between p-1 gap-3  items-center "
                      key={index}
                    >
                   
                      <p1 className="pr-2">{data.Productcategory}</p1>
                   
                      <img
                        className="text-left mb-1 item-center"
                        src={
                          `${process.env.REACT_APP_UPLOADS}` + data.producticon
                        }
                      />

                      {iseditB === data.Productcategory && (
                        <div className="grid grid-cols-1 gap-4">
                          <input
                            type="text"
                            id="Productcategory"
                            name="Productcategory"
                            onChange={(e) => setCategory(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Enter category name"
                            value={category}
                          />

              <div className="">
                  <input
                    type="file"
                    id="producticon"
                    name="producticon"
                    onChange={(e) => setMyproducticon({ [e.target.name]: e.target.files[0] }, setPriviewicon(e.target.files[0]))}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {priviewicon && <img className="mt-1" src={URL.createObjectURL(priviewicon)} />}
                </div>

                          <button
                            type="button"
                            onClick={() => Editcategory(data._id)}
                            className=" text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                          >
                            Edit
                          </button>
                        </div>
                      )}

                      <div className=" flex gap-5">
                        <EditIcon
                          onClick={() => (setCategory(data.Productcategory), setIseditB(data.Productcategory))}
                          sx={{ color: "teal", cursor: "pointer" }}
                        />

                        <RemoveCircleOutlineIcon
                          onClick={() =>
                            deletecategory(data._id)
                            // setModeldata((preValues) =>
                            //   preValues.filter((values) => values !== data.Productcategory)
                            // )
                          }
                          sx={{ color: "teal", cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="mt-10 text-center">
              
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default PortfolioManagement;
