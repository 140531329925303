import React from "react";
import Heading from "../../components/helper/Heading";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const WhySmartTechnica = () => {
  return (
    <div className="relative max-w-[95%] md:max-w-[85%] lg:max-w-[80%] mx-auto mt-10">
      <Heading
        styles="absolute top-5 esm:top-12 w-full text-center mx-auto text-[34px] text-blue"
        image="/assets/images/home/smart.svg"
        text="Why Smart Technica ?"
      />
      <img
        className="-z-[999] absolute bottom-[0%] left-[25%]"
        src="/assets/images/servicesNew/BlueCircle.svg"
        alt=""
      />
      <img
        className="-z-[999] absolute top-[20%] right-[50%]"
        src="/assets/images/servicesNew/YellowCircle.svg"
        alt=""
      />
      <div>
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          className="bg-[#edf5fe] text-center mt-3 md:mt-6 rounded-2xl"
        >
          <p className="p-5 text-[18px]">
            Smart Technica offers outstanding mobile application, game, website
            design and development services. We love taking ideas and turning
            them into reality. We are passionate, creative, and have experience
            developing products as entrepreneurs.We’re fast, precise, and
            awesome.We strongly believe in customer satisfaction and ROI for
            their business. We are not satisfied until you are! Honesty and high
            work ethics are our style of work.
          </p>
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 mt-10 gap-10 ">
          <div
            data-aos="fade-right"
            data-aos-duration="1500"
            className="m-auto animate-stboy"
          >
            <img className="" src="/assets/images/home/stboy.png" alt="" />
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1500"
            className="mx-auto text-blue"
          >
            <div className="flex">
              <div className="mr-3">
                <ArrowForwardIcon color="primary" />
              </div>
              <div>
                <h2 className="text-black">
                  We’re Passionate About What We Do
                </h2>
              </div>
            </div>
            <div className="flex my-5">
              <div className="mr-3">
                <ArrowForwardIcon color="primary" />
              </div>
              <div>
                <h2 className="text-black">Results Oriented Culture</h2>
              </div>
            </div>
            <div className="flex">
              <div className="mr-3">
                <ArrowForwardIcon color="primary" />
              </div>
              <div>
                <h2 className="text-black">Expertise And Innovative Approach Data Security and Compliance</h2>
              </div>
            </div>
            <div className="flex my-5">
              <div className="mr-3">
                <ArrowForwardIcon color="primary" />
              </div>
              <div>
                <h2 className="text-black">We Are A One-Stop-Shop With All Creative Brains Scalability and Flexibility</h2>
              </div>
            </div>
            <div className="flex">
              <div className="mr-3">
                <ArrowForwardIcon color="primary" />
              </div>
              <div>
                <h2 className="text-black">
                  Rigorous Performance Management
                </h2>
              </div>
            </div>
            <div className="flex my-5">
              <div className="mr-3">
                <ArrowForwardIcon color="primary" />
              </div>
              <div>
                <h2 className="text-black">Affordable Cost</h2>
              </div>
            </div>
            <div className="flex">
              <div className="mr-3">
                <ArrowForwardIcon color="primary" />
              </div>
              <div>
                <h2 className="text-black">
                  Communication And Transparency Proactive Support and Maintenance
                </h2>
              </div>
            </div>
            <div className="flex mt-5">
              <div className="mr-3">
                <ArrowForwardIcon color="primary" />
              </div>
              <div>
                <h2 className="text-black">We work collaboratively Client References and Recommendations</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySmartTechnica;
