import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "../../components/helper/Button/Button";
import api from "../../helper/api";
import { CONTACT, EMAIL } from "../../helper/endpoints";
import validationSchema from "./contactSchema";

const ContactUs = ({
  sucessSnackBarOpen,
  setSucessSnackBarOpen,
  warningSnackBarOpen,
  setWarningSnackBarOpen,
}) => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const [submitCount, setSubmitCount] = useState(0);

  const { values, errors, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      try {
        api.postWithToken(`${process.env.REACT_APP_API + CONTACT}`, values);
        api
          .postWithToken(`${process.env.REACT_APP_API + EMAIL}`, values)
          .then(
            (res) => resetForm(),
            setSucessSnackBarOpen(!sucessSnackBarOpen),
            setSubmitCount(0)
          );
      } catch {
        setWarningSnackBarOpen(!warningSnackBarOpen);
      }
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        id="first_name"
        className=" mt-5 bg-gray-50 border border-blue text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
        placeholder="Name"
        name="name"
        value={values.name}
        onChange={handleChange}
      />
      <p className="ml-2 text-xs text-red-500">
        {submitCount === 1 && errors.name}
      </p>
      <div className="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2">
        <div>
          <input
            type="email"
            id="first_name"
            className="bg-gray-50 border border-blue text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
            placeholder="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          <p className="ml-2 text-xs text-red-500">
            {submitCount === 1 && errors.email}
          </p>
        </div>
        <div>
          <input
            type="number"
            id="first_name"
            className="bg-gray-50 border border-blue text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
            placeholder="Phone"
            name="phone"
            // maxLength="12"
            value={values.phone}
            onChange={handleChange}
          />
          <p className="ml-2 text-xs text-red-500">
            {submitCount === 1 && errors.phone}
          </p>
        </div>
      </div>
      <textarea
        rows={5}
        type="text"
        id="first_name"
        className="mt-5 bg-gray-50 border border-blue text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
        placeholder="Message"
        name="message"
        value={values.message}
        onChange={handleChange}
      />
      <p className="ml-2 text-xs text-red-500">
        {submitCount === 1 && errors.message}
      </p>

      <Button
        style="bg-blue rounded-[8px] w-full py-2 text-white mt-5"
        text="Submit"
        type="submit"
        onClick={() => setSubmitCount(1)}
      />
    </form>
  );
};

export default ContactUs;
