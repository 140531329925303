import React, { useState } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { SERVICES } from "../../../helper/endpoints";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../helper/api";
import Warning from "../../../components/SnackBars/SnackBar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  pt: 2,
  px: 4,
  pb: 3,
};

const Add = ({
  addModel,
  setAddModel,
  setSucessSnackBarOpen,
  setWarningSnackBarOpen,
  getAllServicesData,
}) => {
  const [files, setFiles] = useState();
  const [heroimg, setHeroimg] = useState();
  const [headingImg, setHeadingImg] = useState();
  const [formValue, setFormValue] = useState({
    title: "",
    header: "",
    description: "",
  });
  const [descriptionArray, setDescriptionArray] = useState([]);
  const [description, setDescription] = useState([]);
  const [open, setOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (files && heroimg && headingImg) {
      const formData = new FormData();
      files.forEach((file) => formData.append("images", file));
      formData.append("headingImages", headingImg);
      formData.append("heroImages", heroimg);
      formData.append("points", JSON.stringify(descriptionArray));
      formData.append("description", formValue.description);
      formData.append("header", formValue.header);
      formData.append("title", formValue.title);

      api
        .postWithToken(`${process.env.REACT_APP_API + SERVICES}`, formData)
        .then((res) => {
          setAddModel(false);
          // getContact();
          setFiles(null);
          setSucessSnackBarOpen(true);
          getAllServicesData();
          setFormValue({
            title: "",
            header: "",
            description: "",
          });
          setHeadingImg(null);
          setHeroimg(null);
        })
        .catch((err) => {
          console.log(err);
          setWarningSnackBarOpen(true);
        });
    } else {
      setWarningSnackBarOpen(true);
    }
  };
  const handelDescriptionAdd = () => {
    descriptionArray.includes(description)
      ? setOpen(true)
      : setDescriptionArray((preValue) => [...preValue, description]);
    setDescription("");
  };

  return (
    <Modal
      open={addModel}
      onClose={() => {
        setAddModel(false);
        setFiles(null);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-descriptionArray"
    >
      <>
        <form onSubmit={handleSubmit}>
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-3xl p-5 h-[80%] overflow-y-scroll md:p-10 w-[90%] md:w-[600px]">
            <div className="absolute top-2 right-2">
              <IconButton
                onClick={() => {
                  setAddModel(false);
                  setFiles(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="mt-5">
              <div className="mt-5">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  onChange={({ target }) =>
                    setFormValue({ ...formValue, title: target.value })
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Service Title"
                  value={formValue.title}
                  required
                />
              </div>
              <div className="mt-5">
                <label
                  htmlFor="header"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Header
                </label>
                <input
                  type="text"
                  id="header"
                  name="header"
                  onChange={({ target }) =>
                    setFormValue({ ...formValue, header: target.value })
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Service Header"
                  value={formValue.header}
                  required
                />
              </div>
              <div className="mt-5">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Description
                </label>
                <textarea
                  rows={3}
                  type="text"
                  id="description"
                  name="description"
                  onChange={({ target }) =>
                    setFormValue({ ...formValue, description: target.value })
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Service Description"
                  value={formValue.description}
                  required
                />
              </div>
              <div className="mt-5">
                <label
                  htmlFor="descriptionArray"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Points
                </label>
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <input
                      type="text"
                      id="Points"
                      name="Points"
                      value={description}
                      onChange={(e) =>
                        e.target.value !== "" && setDescription(e.target.value)
                      }
                      rows={5}
                      placeholder="Enter Points"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div
                    className="bg-[teal] rounded-[50%] p-2 cursor-pointer"
                    onClick={handelDescriptionAdd}
                  >
                    <AddCircleOutlineIcon sx={{ color: "white" }} />
                  </div>
                </div>
                <ul className="mx-auto w-[96%] mt-5 space-y-2">
                  {descriptionArray &&
                    descriptionArray?.map((value, index) => (
                      <li key={index} className="text-sm list-outside flex">
                        <span className="flex-1">{value}</span>
                        <span
                          className="bg-[teal] rounded-[50%] cursor-pointer"
                          onClick={() =>
                            setDescriptionArray((preValues) =>
                              preValues.filter((values) => values !== value)
                            )
                          }
                        >
                          <RemoveCircleOutlineIcon sx={{ color: "white" }} />
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="mt-5">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Images
                </label>
                <input
                  multiple
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="file"
                  name="smartimages"
                  onChange={(e) => setFiles([...e.target.files])}
                />
              </div>
              <div className="mt-5">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Hero Images
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="file"
                  name="heroimage"
                  onChange={(e) => setHeroimg(e.target.files[0])}
                />
              </div>
              <div className="mt-5">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Heading Images
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="file"
                  name="headingimage"
                  onChange={(e) => setHeadingImg(e.target.files[0])}
                />
              </div>
              <div className="mt-10 text-center">
                <button
                  type="submit"
                  className="text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
        <Warning
          open={open}
          setOpen={setOpen}
          text="This value is already present !"
          type="warning"
        />
      </>
    </Modal>
  );
};

export default Add;
