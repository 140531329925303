import * as yup from "yup";

export default yup.object().shape({

  name: yup.string().required("Name is required !").matches(/^[a-zA-Z]+$/, 'Name should contain only letters'),
  email: yup.string().email().required("Email is required !"),
  phone: yup.string().min(2, 'Minimum 2 number are required').max(12, 'Maximum 12 number are allowed').required('Phone is required'),
  message: yup.string().required("Message is required !")

});
