import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { IconButton, Modal } from "@mui/material";
import Warning from "../../../components/SnackBars/SnackBar";
import api from "../../../helper/api";
import { JOBS } from "../../../helper/endpoints";
import CloseIcon from "@mui/icons-material/Close";
import SucessSnackBar from "../../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../../components/SnackBars/SnackBar";
import usePreview from "../usePreview";

const Add = ({ model, setModel, getContact }) => {
  const [descriptionArray, setDescriptionArray] = useState([]);
  const [description, setDescription] = useState([]);
  const [open, setOpen] = useState(false);
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [myimage, setMyimage] = useState(null);
  const uploadedImg = usePreview(myimage);
  // const [myvalues, setValues] = useState("");

  const initialValues = {
    job_title: " ",
    job_image: "",
    position: "",
    experience: "",
    can_fresher_apply: false,
    long_description: "",
  };

  // useEffect(() => {
  //   setValues(initialValues);
  // }, [editid]);

  const { values, handleSubmit, handleChange, setFieldValue, resetForm } =
    useFormik({
      initialValues,
      onSubmit: (value) => {
        try {
          const formData = new FormData();
          formData.append("job_image", value.job_image, value.job_image.name);
          formData.append("job_title", value.job_title);
          formData.append("position", value.position);
          formData.append("experience", value.experience);
          formData.append("can_fresher_apply", value.can_fresher_apply);
          formData.append("long_description", value.long_description);
          formData.append("description", JSON.stringify(descriptionArray));
          api
            .postWithToken(`${process.env.REACT_APP_API + JOBS}`, formData)
            .then((res) => {
              getContact();
              resetForm();
              setMyimage(null);
              setDescriptionArray("");
              setSucessSnackBarOpen(!sucessSnackBarOpen);
              setModel(!model);
            })
            .catch((err) => setWarningSnackBarOpen(!warningSnackBarOpen));
        } catch {
          setWarningSnackBarOpen(!warningSnackBarOpen);
        }
      },
    });

  const handelDescriptionAdd = () => {
    descriptionArray.includes(description)
      ? setOpen(true)
      : setDescriptionArray((preValue) => [...preValue, description]);
    setDescription("");
  };

  const handleClose = () => {
    setModel(false);
    resetForm();
    setFieldValue(" ");
    setMyimage(null);
    setDescription("");
    setDescriptionArray("");
    // setCategorydata("");
  };

  return (
    <>
      <SucessSnackBar
        open={sucessSnackBarOpen}
        setOpen={setSucessSnackBarOpen}
        text="Card Added Successfully !"
        type="success"
      />
      <ErrorSnackBar
        open={warningSnackBarOpen}
        setOpen={setWarningSnackBarOpen}
        text="Something Missing !"
        type="error"
      />
      <Modal
        open={model}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-descriptionArray"
        style={{ overflow: "scroll" }}
      >
        <form onSubmit={handleSubmit} encType="encType='multipart/form-data'">
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-3xl p-5 md:p-10 w-[90%] overflow-y-scroll h-[90%]  md:w-[600px]">
            <div className="absolute top-2 right-2">
              <IconButton onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <label
                htmlFor="job_image"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Upload Job Image
              </label>
              <input
                type="file"
                id="job_image"
                name="job_image"
                onChange={(e) => (
                  setFieldValue("job_image", e.target.files[0]), setMyimage(e)
                )}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {uploadedImg[0]?.job_image && (
                <img
                  className="text-left mb-1 w-[100px] h-[100px]"
                  src={uploadedImg[0]?.job_image}
                />
              )}
            </div>
            <div className="mt-5">
              <label
                htmlFor="job_title"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Job Title
              </label>
              <input
                type="text"
                id="job_title"
                name="job_title"
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Job Title"
                value={values.job_title}
              />
            </div>
            <div className="mt-5">
              <label
                htmlFor="position"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Position
              </label>
              <input
                type="number"
                id="position"
                name="position"
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Positions"
              />
            </div>
            <div className="mt-5">
              <label
                htmlFor="experience"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Experience
              </label>
              <input
                type="text"
                id="experience"
                name="experience"
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Positions"
              />
            </div>
            <div className="mt-5">
              <label
                htmlFor="long_description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Long Description
              </label>
              <input
                type="text"
                id="long_description"
                name="long_description"
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Description"
              />
            </div>
            <div className="mt-5">
              <label
                htmlFor="descriptionArray"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Description
              </label>
              <div className="flex items-center gap-4">
                <div className="flex-1">
                  <input
                    type="text"
                    id="descriptionArray"
                    name="descriptionArray"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={5}
                    placeholder="Enter Description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div
                  className="bg-[teal] rounded-[50%] p-2 cursor-pointer"
                  onClick={handelDescriptionAdd}
                >
                  <AddCircleOutlineIcon sx={{ color: "white" }} />
                </div>
              </div>
              <ul className="mx-auto w-[96%] mt-5 space-y-2">
                {descriptionArray &&
                  descriptionArray?.map((value, index) => (
                    <li key={index} className="text-sm list-outside flex">
                      <span className="flex-1">{value}</span>
                      <span
                        className="bg-[teal] rounded-[50%] cursor-pointer"
                        onClick={() =>
                          setDescriptionArray((preValues) =>
                            preValues.filter((values) => values !== value)
                          )
                        }
                      >
                        <RemoveCircleOutlineIcon sx={{ color: "white" }} />
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="mt-5 flex items-center gap-2">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  id="can_fresher_apply"
                  name="can_fresher_apply"
                  onChange={handleChange}
                  value={values.can_fresher_apply}
                />
                <div
                  className={
                    values.can_fresher_apply
                      ? "w-11 h-6  bg-[#008080] rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                      : "w-11 h-6 bg-gray-200  rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                  }
                ></div>
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Can fresher apply ?
                </span>
              </label>
            </div>
            <div className="mt-10 text-center">
              <button
                type="submit"
                className=" text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Modal>
      <Warning
        open={open}
        setOpen={setOpen}
        text="This value is already present !"
        type="warning"
      />
    </>
  );
};

export default Add;
