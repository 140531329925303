import { useState, useEffect } from "react";

const usePreview = (e) => {

    const [uploadedImg, setUploadedImg] = useState(null);

   useEffect(() => {
   
    if(e !== null){
      var file = e?.target?.files[0];
      var reader = new FileReader();
      var url = reader?.readAsDataURL(file);
      reader.onloadend = () => {
        setUploadedImg({ ...uploadedImg, [e.target.name]: reader?.result });
      };
    }else{
      setUploadedImg(null);
    }

  }, [e]);

  return [uploadedImg];
};

export default usePreview;
