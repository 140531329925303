import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Heading from "../helper/Heading";
import Card from "../helper/Carousel/Card";
import MiniCarousel from "../helper/Carousel/MiniCarousel";

const ProtfolioOfer = () => {
  const options = {
    margin: 30,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1350: {
        items: 3,
      },
      1815: {
        items: 4,
      },
    },
  };
  return (
    <>
      <Heading
        text="The Value We Offer Our Clients"
        image="/assets/images/Career/Connect.svg"
        styles="absolute w-full top-12 text-center mx-auto text-[34px] text-blue m-auto"
      />
      <OwlCarousel
        data-aos="fade-up"
        data-aos-duration="1500"
        className="slider-items owl-theme map-slider owl-carousel owl-loaded owl-drag"
        stageClass="owl-stage h-[400px] flex items-center"
        loop
        {...options}
      >
        <Card
          image="/assets/images/Portfolio/handshake.svg"
          heading="Consultative approach"
          color="ring-[#2B747E] bg-[#EFFDFF]"
          headingClass={"text-[#2B747E]"}
          contentClass={"text-[#000]"}
          text="We have across industry experience working with 1500+ clients over
          17 years, so we can offer a unique and consultative perspective to
          software engineering that you won't find with other development and
          technology consulting providers."
        />
        <Card
          color="ring-[#C24278] bg-[#fff6fa]"
          headingClass={"text-[#C24278]"}
          contentClass={"text-[#000]"}
          image="/assets/images/Portfolio/hand.svg"
          heading="All-in-one provider"
          text="Our services are comprehensive, so we can address your various needs
          including development, software testing, end-to-end support &
          maintenance, cloud services, building al solutions, and so much more."
        />
        <Card
          color="ring-[#116EB4] bg-[#F6FBFF]"
          headingClass={"text-[#1377C3]"}
          contentClass={"text-[#000]"}
          image="/assets/images/Portfolio/cost.svg"
          heading="Minimize development costs"
          text="Cloud-based architectures, ready-to-use frameworks and platforms,
        and public apis make our development services cost- effective and
        reliable. our agile methodologies remove risks related to rework and
        enhance the overall product."
        />
        <Card
          color="ring-[#9837FF] bg-[#FCF8FF]"
          headingClass={"text-[#9837FF]"}
          contentClass={"text-[#000]"}
          image="/assets/images/Portfolio/customer.svg"
          heading="Long term partnership"
          text="We do not stop after delivering and installing our best Custom
        Software Solutions. We go beyond that by creating a long-term
        relationship in the form of regular maintenance support for the
        seamless functioning of your business."
        />
      </OwlCarousel>
      <div className="mt-10 shadow-xl max-w-full">
        <MiniCarousel />
      </div>
    </>
  );
};

export default ProtfolioOfer;
