import { Modal } from "@mui/material";
import React, { useState } from "react";
import "./ImageContainer.css";
import { RWebShare } from "react-web-share";
import { CATEGORYICONS } from "../../../helper/constants";

const ImageContainer = (props) => {

  const { fullImage, image, setAnimation, setIsImgModal, categoryicon } = props;
  const [isModal, setIsModal] = useState(false);
  const [imageNumber, setImageNumber] = useState(0);

  const hendleAnimation = () => {
    setAnimation(true);
  };

  const leaveAnimation = () => {
    setAnimation(false);
  };

  const categoryIcons = [];

  for (let i = 0; i < categoryicon?.length; i++) {
    categoryIcons.push(
      <div className={`absolute top-[100%] ${CATEGORYICONS[i]?.left + " " + CATEGORYICONS[i]?.transition + " " + CATEGORYICONS[i]?.hover} z-10`}>
        <a
          href="#"
          className="w-[30px] h-[30px] inline-block bg-white rounded-full"
        >
          <img
            className=""
            src={`${process.env.REACT_APP_UPLOADS}${categoryicon[i].icon}`}
            alt=""
          />
        </a>
      </div>
    )
  }

  return (
    <>
      <div
        onMouseEnter={hendleAnimation}
        onMouseLeave={leaveAnimation}
        className="section-padding"
        onClick={() => (
          setIsModal(!isModal), setIsImgModal((prevState) => !prevState)
        )}
      >
        <div className="xpt group">
          <div className="xpt-hex xpt-cw ex-cls rounded-[10px]">
            <div className="xpt-ihex">
              <div className="ex-2">
                <img src={image} alt="" />

                <div className="absolute top-0 w-full h-full">
                  {categoryIcons.map(icons => icons)}
                </div>


                {/* // icon statis  */}

                {/* <div className="icons absolute h-full s-icon">
                  <a
                    href="#"
                    className="w-[30px] h-[30px] inline-block bg-white rounded-full "
                  >
                    <img
                      className=""
                      src="/assets/images/Contact/skype.png"
                      alt=""
                    />
                  </a>
                </div>
                <div className="icons absolute h-full t-icon">
                  <a
                    href="#"
                    className="w-[30px] h-[30px] inline-block bg-white rounded-full "
                  >
                    <img
                      className=""
                      src="/assets/images/Contact/facebook.png"
                      alt=""
                    />
                  </a>
                </div>

                <div className="icons absolute h-full four-icon">
                  <a
                    href="#"
                    className="w-[30px] h-[30px] inline-block bg-white rounded-full "
                  >
                    <img
                      className=""
                      src="/assets/images/Contact/linkedin.png"
                      alt=""
                    />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={isModal}
          onClose={() => (setIsModal(!isModal), setIsImgModal(false))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "scroll" }}
        >
          <>
            <div className="relative mx-auto mt-5 outline-none">
              <div className="md:[55px] lg:[50px] xl:[45px] 2xl:[40px]">
                <img className="max-w-[85%] rounded-t-[40px] mx-auto" src={`${process.env.REACT_APP_UPLOADS}/${fullImage[imageNumber]}`} alt="" />
              </div >
              {
                fullImage.length > 1
                && <div className="fixed top-[calc(50%-20px)] w-full flex justify-between px-4">
                  <button onClick={e => {
                    e.stopPropagation();
                    setImageNumber(prevState => prevState === 0 ? fullImage.length - 1 : prevState - 1)
                  }}>
                    <img src="assets/images/PREVIOUS.png" alt="" />
                  </button>
                  <button onClick={e => {
                    e.stopPropagation();
                    setImageNumber(prevState => prevState === fullImage.length - 1 ? 0 : prevState + 1)
                  }}>
                    <img src="assets/images/NEXT.png" alt="" />
                  </button>
                </div>
              }
            </div >

            <div className="fixed top-[1%] right-[1%] flex flex-col gap-5  w-[20px] md:w-[40px]">
              <div
                onClick={e => {
                  e.stopPropagation();
                  setIsModal(false)
                  setIsImgModal(false)
                }}
                className="cursor-pointer"
              >
                <img
                  src="/assets/images/Portfolio/page open/close-icon.png"
                  alt=""
                />
              </div>
              <div>
                <RWebShare
                  data={{
                    text: "Web Share - Smart Technica",
                    url: "http://localhost:3000/portfolio",
                    title: "Smart Technica",
                  }}
                  sites={[
                    "facebook",
                    "twitter",
                    "whatsapp",
                    "telegram",
                    "linkedin",
                  ]}
                  onClick={() => console.log("shared successfully!")}
                >
                  <button>
                    <img
                      src="/assets/images/Portfolio/page open/share-icon.png"
                      alt=""
                    />
                  </button>
                </RWebShare>
              </div>
              <img src="" alt="" />
            </div>
          </>
        </Modal >
      </div >
    </>
  );
};

export default ImageContainer;
