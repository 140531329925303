import React from "react";

const ScrollTop = () => {
  const rocketImgRef = React.useRef();
  const rocketMainRef = React.useRef();

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => setScrollPosition(window.scrollY);

  React.useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (scrollPosition > 0) {
    rocketMainRef.current?.classList.remove("hidden");
  } else {
    rocketMainRef.current?.classList.add("hidden");
    rocketImgRef.current?.classList.remove("rocket-launched");
    rocketMainRef.current?.classList.remove("rocket-launched-bg");
  }

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    rocketImgRef.current.classList.add("rocket-launched");
    rocketMainRef.current.classList.add("rocket-launched-bg");
  };

  return (
    <div
      onClick={(e) => handleClick(e)}
      ref={rocketMainRef}
      className='hidden scrolltop-btn fixed bottom-[2%] right-4 h-16 w-16 md:h-20 md:w-20 rounded-[50%] bg-[#e7edfb] cursor-pointer
        before:content-[""] before:bg-[#dbe6f8] before:absolute before:top-2 before:bottom-2 before:left-2 before:right-2 before:rounded-[50px]
        after:content-[""] after:bg-[#d2e2f6] after:absolute after:top-4 after:bottom-4 after:left-4 after:right-4 after:rounded-[50px] z-50'
    >
      <img
        ref={rocketImgRef}
        className="scrolltop-img block fixed bottom-0 h-[80px] right-[38px] md:h-[100px] md:right-[42px] z-10"
        src="/assets/images/Group 78208.svg"
        alt=""
      />
    </div>
  );
};

export default ScrollTop;
