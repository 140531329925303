import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import api from "../../helper/api";
import { ADMIN } from "../../helper/endpoints";
import { useDispatch } from "react-redux";
import { token } from "../../redux/tokenSlice";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues,
    onSubmit: (values) => {
      api
        .post(`${process.env.REACT_APP_API + ADMIN}`, values)
        .then((res) => {
          sessionStorage.setItem("token", res.data.data.token);
          dispatch(token(res.data.data.token));
          navigate("/admin/quote-management");
        })
        .catch((err) => console.log(err));
    },
  });

  return (
    <section className="w-screen h-screen bg-blue">
      <div className="flex flex-col min-h-full pt-16 sm:pt-24">
        <div className="mx-auto flex-all-center">
          <img src="/assets/images/logo.png" alt="" />
        </div>
        <h1 className="my-3 text-2xl font-semibold text-center text-white">
          Sign in to account
        </h1>
        <div className="flex-col items-center justify-center flex-auto w-full h-full p-12 mx-auto bg-white sm:flex-none sm:rounded-2xl sm:max-w-lg">
          <form className="pb-4" onSubmit={handleSubmit}>
            <label className="block font-medium">Email address</label>
            <div className="mt-1">
              <input
                name="email"
                type="email"
                value={values.email}
                autoComplete=""
                onChange={handleChange}
                placeholder=""
                className="w-full px-3 py-2 border-2 border-gray-300 rounded-lg shadow-none outline-none focus:border-primary-500 focus:shadow-lg focus:shadow-primary-100"
              />
            </div>
            <label className="block font-medium mt-5">Password</label>
            <div className="mt-1">
              <input
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                autoComplete=""
                placeholder=""
                className="w-full px-3 py-2 border-2 border-gray-300 rounded-lg shadow-none outline-none focus:border-primary-500 focus:shadow-lg focus:shadow-primary-100"
              />
            </div>
            <button
              type="submit"
              className="text-white bg-blue mt-10 flex justify-center w-full px-4 py-2 text-base font-semibold duration-300 border border-transparent rounded-md shadow flex-all-center bg-primary-500 hover:bg-primary-600 hover:shadow-lg hover:shadow-primary-100"
            >
              <svg
                className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                x-show="formloading"
                style={{ display: "none" }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span>Submit</span>
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AdminLogin;
