import React from "react";

const SiteHeroSection = () => {
  return (
    <div className="max-w-[1920px] mx-auto">
      <div className="relative mt-[76px]">
        <img
          className="min-h-[300px] object-cover select-none relative"
          src="/assets/images/home/i/Group 78481.png"
          alt=""
        />
      </div>
      <div className="z-50 absolute w-full text-center flex flex-col items-center top-[22%] xl:top-[28%] space-y-5">
        <h1 className="text-white text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-[30px] font-semibold leading-5 md:w-[80%] lg:w-[70%] 2xl:w-[60%]">
          Explore Our Company
        </h1>
        <h2 className="text-white text-sm md:text-lg lg:text-xl font-normal leading-5 md:w-[80%] lg:w-[50%] opacity-80">
          Smart Technica, we are dedicated to pushing the boundaries of
          technology and delivering innovative solutions that drive success for
          businesses worldwide.
        </h2>
      </div>
    </div>
  );
};

export default SiteHeroSection;
