import React, { useState } from 'react'
import { IconButton, Modal } from "@mui/material";
import { SMARTIMAGES } from '../../../helper/endpoints';
import CloseIcon from "@mui/icons-material/Close";
import api from '../../../helper/api';


const Add = ({ addModel, setAddModel, getContact, setSucessSnackBarOpen, setWarningSnackBarOpen }) => {
    const [files, setFiles] = useState();


    const handleSubmit = (e) => {
        e.preventDefault();

        if (files) {
            const formData = new FormData();
            files.forEach(file => formData.append("smartimages", file))

            api.postWithToken(
                `${process.env.REACT_APP_API + SMARTIMAGES}`,
                formData
            ).then((res) => {
                setAddModel(false);
                getContact();
                setFiles(null);
                setSucessSnackBarOpen(true)
            })
                .catch((err) => {
                    setWarningSnackBarOpen(true)
                });
        }else{
            setWarningSnackBarOpen(true);
        }
    }

    return (
        <Modal
            open={addModel}
            onClose={() => {
                setAddModel(false)
                setFiles(null)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-descriptionArray"
            style={{ overflow: "scroll" }}
        >
            <form onSubmit={handleSubmit} encType="encType='multipart/form-data'">
                <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-3xl p-5 md:p-10 w-[90%] md:w-[600px]">
                    <div className="absolute top-2 right-2">
                        <IconButton onClick={() => {
                            setAddModel(false)
                            setFiles(null)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="mt-5">
                        <div className='mt-5'>
                            <input multiple className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type="file" name='smartimages' onChange={e => setFiles([...e.target.files])} />
                        </div>
                        <div className='mt-5 space-y-2'>
                            {files?.map(file => {
                                return <p>{file.name}</p>
                            })}
                        </div>
                        <div className="mt-10 text-center">
                            <button type="submit" className="text-white bg-teal-700 hover:bg-teal-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default Add