import React, { useRef } from "react";
import MiniCarousel from "../helper/Carousel/MiniCarousel";
import Particles from "./Particles";

const HomeMain = () => {
  return (
    <>
      <div className="max-w-[1920px] mx-auto">
        <div className="relative mt-[76px]">
          <img
            className="min-h-[300px] object-cover select-none relative"
            src="/assets/images/home/i/Group 78481.png"
            alt=""
          />
          {/* <Particles /> */}
          {/* <div className="absolute w-full h-full top-[76px] hidden lg:inline-block">
            <span className='homeIcons top-[16%] right-[49%] w-[38px] bg-[url("/public/assets/images/home/i/png/li/Group77789.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77789.png")]'></span>
            <span className='homeIcons top-[13.5%] left-[2.7%] w-[49px] bg-[url("/public/assets/images/home/i/png/li/Group77790.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77790.png")]'></span>
            <span className='homeIcons top-[9.5%] left-[29%] w-[40px] bg-[url("/public/assets/images/home/i/png/li/Group77791.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77791.png")]'></span>
            <span className='homeIcons bottom-[36%] left-[22%] w-[45px] bg-[url("/public/assets/images/home/i/png/li/Group77792.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77792.png")]'></span>
            <span className='homeIcons top-[16.5%] right-[37%] w-[40px] bg-[url("/public/assets/images/home/i/png/li/Group77793.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77793.png")]'></span>
            <span className='homeIcons bottom-[58%] right-[0.7%] w-[40px] bg-[url("/public/assets/images/home/i/png/li/Group77794.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77794.png")]'></span>
            <span className='homeIcons top-[15%] left-[13.2%] w-[45px] bg-[url("/public/assets/images/home/i/png/li/Group77795.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77795.png")]'></span>
            <span className='homeIcons bottom-[55%] left-[13%] w-[48px] bg-[url("/public/assets/images/home/i/png/li/Group77796.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77796.png")]'></span>
            <span className='homeIcons top-[29%] left-[8.2%] w-[36px] bg-[url("/public/assets/images/home/i/png/li/Group77797.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77797.png")]'></span>
            <span className='homeIcons bottom-[44%] left-[5%] w-[42px] bg-[url("/public/assets/images/home/i/png/li/Group77799.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77799.png")]'></span>
            <span className='homeIcons top-[16%] right-[25%] w-[44px] bg-[url("/public/assets/images/home/i/png/li/Group77802.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77802.png")]'></span>
            <span className='homeIcons top-[8%] right-[4%] w-[47px] bg-[url("/public/assets/images/home/i/png/li/Group77804.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77804.png")]'></span>
            <span className='homeIcons bottom-[30%] right-[23%] w-[55px] bg-[url("/public/assets/images/home/i/png/li/Group77805.png")] hover:bg-[url("/public/assets/images/home/i/png/dr/Group77805.png")]'></span>
          </div> */}
        </div>
        <div className="z-50 absolute w-full text-center flex flex-col items-center top-[22%] xl:top-[28%] space-y-5">
          <h1 className="text-white text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-[30px] font-semibold leading-5 md:w-[80%] lg:w-[70%] 2xl:w-[60%]">
            We deliver innovative technology strategies that solve business
            problems
          </h1>
          <h2 className="text-white text-sm md:text-lg lg:text-xl font-normal leading-5 md:w-[80%] lg:w-[50%] opacity-80">
            Our perfomance is your success.Our passion is innovation.Our
            expertise is unmatched.We give you more
          </h2>
        </div>
        {/* <img src="/assets/images/HeaderImage.png" alt="" className="w-full" /> */}
        {/* <img src="/assets/images/HeaderImage2.png" alt="" className="w-full" /> */}
        {/* <div className="gap-x-2 esm:gap-x-20 md:gap-x-0 mt-5 px-0 esm:px-4 md:min-h-[307px] ml-0 md:ml-[35px] xl:ml-0 flex justify-center items-center md:justify-center xl:justify-between xl:px-10 2xl:px-15 relative">
          <img
            className="hidden xl:block"
            src="/assets/images/home/i/Group 78154.png"
            alt=""
          />
          <img
            className="animate-bulb object-contain hidden md:block"
            src="/assets/images/home/i/Group 78470.png"
            alt=""
          />
          <img
            className="hidden xl:block"
            src="/assets/images/home/i/Group 78412.png"
            alt=""
          />
          <div className="hidden md:flex absolute top-[7%] left-[calc(50%-355px)]">
            <div className="relative">
              <img src="/assets/images/home/i/Group 78232.png" alt="" />
              <div className="absolute top-0 pt-1 pl-[18px] pr-[30px] text-center">
                <img
                  className="mx-auto"
                  src="/assets/images/home/i/Group 78471.png"
                  alt=""
                />
                <p className="text-[13px] text-blue">
                  Technology that makes life better.If you can dream it,we can
                  make it
                </p>
              </div>
            </div>
            <div className="pt-6">
              <img src="/assets/images/home/i/Group 78216.png" alt="" />
            </div>
          </div>
          <div className="hidden md:flex absolute flex-col bottom-0 left-[calc(50%-265px)]">
            <div>
              <img
                className="ml-auto"
                src="/assets/images/home/i/Group 78219.png"
                alt=""
              />
            </div>
            <div className="ml-auto -mt-10 mr-[35px] relative">
              <img src="/assets/images/home/i/Group 78233.png" alt="" />
              <div className="absolute text-center top-0 pt-3 px-[14px]">
                <img
                  className="mx-auto"
                  src="/assets/images/home/i/Group 78472.png"
                  alt=""
                />
                <p className="text-[13px] text-blue">
                  We work hard to bring technology, ideas and more to make your
                  business smarter, simpler, faster
                </p>
              </div>
            </div>
          </div>
          <div className="hidden md:flex absolute top-[28%] right-[calc(50%-320px)]">
            <div>
              <img src="/assets/images/home/i/Group 78220.png" alt="" />
            </div>
            <div className="relative">
              <img src="/assets/images/home/i/Group 78234.png" alt="" />
              <div className="absolute text-center top-0 pt-2 pr-[14px] pl-[20px]">
                <img
                  className="mx-auto"
                  src="/assets/images/home/i/Group 78473.png"
                  alt=""
                />
                <p className="text-[13px] text-blue">
                  Our experience, ideas, and solutions give you the competitive
                  edge let's build something
                </p>
              </div>
            </div>
          </div>
          <div className="md:hidden space-y-3">
            <div className="ring-[#edf1fe] bg-gradient-to-tl from-white via-[#edf1fe] to-white ring-2 w-[200px] p-[10px] text-center rounded-sm">
              <p className="text-[12px] text-blue">
                Technology that makes life better.If you can dream it,we can
                make it
              </p>
            </div>
            <div className="ml-0 esm:-ml-8 ring-[#f7efd4] bg-gradient-to-tl from-white via-[#fef7df] to-white ring-2 w-[200px] p-[10px] text-center rounded-sm">
              <p className="text-[12px] text-blue">
                We work hard to bring technology, ideas and more to make your
                business smarter, simpler, faster
              </p>
            </div>
            <div className="ring-[#fee5e4] bg-gradient-to-tl from-white via-[#fee7e6] to-white ring-2 w-[200px] p-[10px] text-center rounded-sm">
              <p className="text-[12px] text-blue">
                Our experience, ideas, and solutions give you the competitive
                edge let's build something
              </p>
            </div>
          </div>
          <div className="flex h-full md:hidden">
            <img
              className="animate-bulb object-contain"
              src="/assets/images/home/i/Group 78482.png"
              alt=""
            />
          </div>
          <div className="hidden esm:block absolute h-full w-full md:hidden">
            <img
              className="absolute left-[55%] top-[5%]"
              src="/assets/images/home/i/Group 78483.png"
              alt=""
            />
            <img
              className="absolute left-[50%] top-[calc(50%-30.5px)]"
              src="/assets/images/home/i/Group 78484.png"
              alt=""
            />
            <img
              className="absolute left-[55%] bottom-[5%]"
              src="/assets/images/home/i/Group 78485.png"
              alt=""
            />
          </div>
        </div> */}
        {/* <div
          data-aos="zoom-in"
          data-aos-duration="1500"
          // data-aos-once="true"
          className="mt-10 shadow-xl max-w-full"
        >
          <MiniCarousel />
        </div> */}
      </div>
    </>
  );
};

export default HomeMain;
