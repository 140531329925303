import React from "react";
import { Link } from "react-router-dom";

const MenuLink = ({ link, text }) => {
  return (
    <li className="footerlink hover:text-white">
      <Link to={link} className="text-[18px]">
        <span className="footer-hover-line"></span>
        {text}
      </Link>
    </li>
  );
};

export default MenuLink;
