import React, { useState } from "react";
import Quote from "../../components/QuoteForm/Quote";
import Heading from "../../components/helper/Heading";
import RouteAnimation from "../../components/helper/RouteAnimation";
import SucessSnackBar from "../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../components/SnackBars/SnackBar";

const AboutUs = () => {
  const [mission, setMission] = useState("mission");
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);

  return (
    <>
      <SucessSnackBar
        open={sucessSnackBarOpen}
        setOpen={setSucessSnackBarOpen}
        text="Thank you for contacting us !"
        type="success"
      />

      <ErrorSnackBar
        open={warningSnackBarOpen}
        setOpen={setWarningSnackBarOpen}
        text="Something Missing !"
        type="error"
      />

      <RouteAnimation>
        <div className="">
          <Heading
            styles="absolute w-full top-10 esm:top-12 text-center mx-auto"
            image="/assets/images/about us/SVG/about.svg"
            text="Who we are?"
          />
          <div className="rounded-lg p-2 esm:p-7 bg-[#f2f3fe] mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5">
            <img
              data-aos="fade-right"
              data-aos-duration="1500"
              className="mx-auto"
              src="/assets/images/about us/aboutMain.png"
              alt=""
            />
            <div className="p-2 esm:p-7  grid grid-cols-1 gap-5">
              <div
                data-aos="fade-left"
                data-aos-duration="1500"
                className="text-black flex flex-col justify-center gap-2"
              >
                <p className="text-[20px] font-medium">
                  Smart Technica is a web development, software development,
                  mobile application development, mobile game development and
                  all e-commerce solutions providing company. we are committed
                  to
                </p>
                <p className="text-[20px] font-medium">
                  provide best services and it solutions in flexible approach.
                  augmentation of knowledge, easy & best solution of the
                  business issues, long-term relationships with clients awarded
                  us as a reliable and repudiated it solution provider company.
                </p>
              </div>
            </div>
          </div>
          <div className="relative mb-10">
            <img
              className="absolute top-[-120px] left-0"
              src="/assets/images/servicesNew/firstArrow.png"
              alt=""
            />
            <div className="max-w-[95%] md:max-w-[85%] mx-auto">
              <Heading
                styles="absolute w-full top-12 text-center mx-auto text-[34px] text-blue"
                image="/assets/images/about us/SVG/choose.svg"
                text="Why choose us ?"
              />
              <div
                data-aos="fade-up"
                data-aos-duration="1500"
                className="bg-[#f2f3fe] text-center my-3 md:my-6 rounded-2xl"
              >
                <p className="p-5 text-[18px]">
                  We have enhanced our status within a very short period of time
                  in the whole it world. gratification to our clients along with
                  gained and developed precious industry awareness is our
                  obligation. we deliver affordable technical services and
                  design solutions without negotiation on quality. we always
                  assist our clients solve all their issues and to improve the
                  performance of their business. we always look for the expert
                  and dedicated professionals having the high ambitions. if you
                  want to go ahead to work with an organization that appreciate
                  your dedication to your duty then Smart Technica always
                  welcomes you.
                </p>
              </div>
              <div
                data-aos="zoom-in"
                data-aos-duration="1500"
                className="w-full mt-5 mb-10"
              >
                <div className="flex justify-between flex-col xl:flex-row gap-y-4">
                  <div className="flex flex-1 flex-col sm:flex-row gap-y-4">
                    <div className="flex flex-1">
                      <div className="item text-center flex flex-col items-center h-[300px] flex-1">
                        <p>Understanding Requirements</p>
                        <div className="relative">
                          <img
                            src="/assets/images/about us/Group 77456.png"
                            alt=""
                          />
                          <img
                            className="absolute top-8"
                            src="/assets/images/about us/Group 78414.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="item text-center flex flex-col items-center justify-end h-[300px] flex-1">
                        <div className="relative">
                          <img
                            src="/assets/images/about us/Group 77431.png"
                            alt=""
                          />
                          <img
                            className="absolute bottom-8"
                            src="/assets/images/about us/Group 78418.png"
                            alt=""
                          />
                        </div>
                        <p>Creating Strategies</p>
                      </div>
                    </div>
                    <div className="flex flex-1">
                      <div className="item text-center flex flex-col items-center h-[300px] flex-1">
                        <p>Using Right Technology</p>
                        <div className="relative">
                          <img
                            src="/assets/images/about us/Group 77432.png"
                            alt=""
                          />
                          <img
                            className="absolute top-8"
                            src="/assets/images/about us/Group 78421.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="item text-center flex flex-col items-center justify-end h-[300px] flex-1">
                        <div className="relative">
                          <img
                            src="/assets/images/about us/Group 77454.png"
                            alt=""
                          />
                          <img
                            className="absolute bottom-8"
                            src="/assets/images/about us/Group 78423.png"
                            alt=""
                          />
                        </div>
                        <p>Planning</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col sm:flex-row gap-y-4">
                    <div className="flex flex-1">
                      <div className="item text-center flex flex-col items-center h-[300px] flex-1">
                        <p>Working On Design</p>
                        <div className="relative">
                          <img
                            src="/assets/images/about us/Group 77456.png"
                            alt=""
                          />
                          <img
                            className="absolute top-8"
                            src="/assets/images/about us/Group 78424.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="item text-center flex flex-col items-center justify-end h-[300px] flex-1">
                        <div className="relative">
                          <img
                            src="/assets/images/about us/Group 77431.png"
                            alt=""
                          />
                          <img
                            className="absolute bottom-8"
                            src="/assets/images/about us/Group 78426.png"
                            alt=""
                          />
                        </div>
                        <p>Developing The App</p>
                      </div>
                    </div>
                    <div className="flex flex-1">
                      <div className="item text-center flex flex-col items-center h-[300px] flex-1">
                        <p>Testing Phase</p>
                        <div className="relative">
                          <img
                            src="/assets/images/about us/Group 77432.png"
                            alt=""
                          />
                          <img
                            className="absolute top-8"
                            src="/assets/images/about us/Group 78428.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="item text-center flex flex-col items-center justify-end h-[300px] flex-1">
                        <div className="relative">
                          <img
                            src="/assets/images/about us/Group 77454.png"
                            alt=""
                          />
                          <img
                            className="absolute bottom-8"
                            src="/assets/images/about us/Group 78430.png"
                            alt=""
                          />
                        </div>
                        <p>Launching Project</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Heading
                styles="absolute w-full top-12 text-center mx-auto text-[34px] text-blue"
                image="/assets/images/about us/SVG/mission.svg"
                text="Our mission & our vision"
              />
              <img
                className="-z-[999] absolute bottom-[20%] right-[42%]"
                src="/assets/images/servicesNew/YellowCircle.svg"
                alt=""
              />
              <div className="grid grid-cols-1 mt-5 lg:grid-cols-2">
                <div data-aos="fade-right" data-aos-duration="1500">
                  <div className="flex">
                    <div className="relative">
                      <button
                        onClick={() => setMission("mission")}
                        className={
                          mission === "mission"
                            ? "text-xl bg-[#276fae] border px-6 py-2  md:px-16 md:py-5 text-white"
                            : "text-xl border px-6 py-2 border-black md:px-16 md:py-5"
                        }
                      >
                        Our Mission
                      </button>
                      {mission === "mission" && (
                        <img
                          className="mx-auto mt-[-3px]"
                          src="/assets/images/about us/triengle.png"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="relative">
                      <button
                        onClick={() => setMission("vision")}
                        className={
                          mission === "vision"
                            ? "text-xl bg-[#276fae] border px-6 py-2 md:px-16 md:py-5 text-white"
                            : "text-xl border px-6 py-2 border-black md:px-16 md:py-5"
                        }
                      >
                        Our Vision
                      </button>
                      {mission === "vision" && (
                        <img
                          className="mx-auto mt-[-3px]"
                          src="/assets/images/about us/triengle.png"
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  {mission === "mission" ? (
                    <div className="min-h-[225px]">
                      <h1 className="mt-10 text-3xl font-semibold">
                        Creating a better future
                      </h1>
                      <p className="mt-3 text-lg">
                        Gaining and sustaining customer trust with constant
                        technologycal upgration, putting forward the service
                        excellence keeping teamwork forth
                      </p>
                      <div className="flex items-center mt-3">
                        <img
                          className="h-[25px] mr-3"
                          src="/assets/images/about us/checkbox.png"
                          alt=""
                        />
                        <p>We are Award Winning Company</p>
                      </div>
                      <div className="flex items-center mt-3">
                        <img
                          className="h-[25px] mr-3"
                          src="/assets/images/about us/checkbox.png"
                          alt=""
                        />
                        <p>Always Work With Energetic Team</p>
                      </div>
                    </div>
                  ) : (
                    <div className="min-h-[225px]">
                      <h1 className="mt-10 text-3xl font-semibold">VISION</h1>
                      <p className="mt-4">
                        To develop the organizational culture where our
                        employees are inspired to give their best and serve our
                        stakeholders effectively and efficiently by updating
                        themselves for new technologies with sustainable and
                        constant growth rate.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-duration="1500"
                  className="flex items-center"
                >
                  <img
                    className="mx-auto"
                    src="/assets/images/about us/missionImg.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="bg-[#f2f3fe] mt-12 py-6 rounded-2xl">
                <div className=" max-w-[95%] md:max-w-[85%] mx-auto">
                  <Heading
                    styles="absolute w-full top-12 text-center mx-auto text-[34px] text-blue"
                    image="/assets/images/about us/SVG/CORE.svg"
                    text="Core values"
                  />
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  className="max-w-[95%] md:max-w-[85%] mx-auto gap-5 mt-10 grid grid-cols-1 md:grif-cols-2 lg:grid-cols-4"
                >
                  <div className="w-full text-center">
                    <img
                      className="mx-auto"
                      src="/assets/images/about us/accuracy.png"
                      alt=""
                    />
                    <h1 className="my-3 text-[25px] font-semibold">Accuracy</h1>
                    <p className="text-[16px] opacity-80">
                      Accuracy is the degree of conformity of a measured
                      quantity to its actual-true value. We believe accurate
                      solution and service make client more strong, opportunity
                      achiever and happy.
                    </p>
                  </div>
                  <div className="w-full text-center">
                    <img
                      className="mx-auto"
                      src="/assets/images/about us/availability.png"
                      alt=""
                    />
                    <h1 className="my-3 text-[25px] font-semibold">
                      Availability
                    </h1>
                    <p className="text-[16px] opacity-80">
                      Ensuring round-the-clock support, we prioritize our
                      clients' needs, providing timely and reliable solutions
                      for their IT requirements.
                    </p>
                  </div>
                  <div className="w-full text-center">
                    <img
                      className="mx-auto"
                      src="/assets/images/about us/transparency.png"
                      alt=""
                    />
                    <h1 className="my-3 text-[25px] font-semibold">
                      Transparency
                    </h1>
                    <p className="text-[16px] opacity-80">
                      We believe the pillar of the client success remains with
                      transparency only where client feels confident during
                      working with us. We aimed to be bank for our client where
                      their trust & faith is the fixed deposit with us!
                    </p>
                  </div>
                  <div className="w-full text-center">
                    <img
                      className="mx-auto"
                      src="/assets/images/about us/ideas.png"
                      alt=""
                    />
                    <h1 className="my-3 text-[25px] font-semibold">Ideas</h1>
                    <p className="text-[16px] opacity-80">
                      Keep exchanging Ideas to provide innovative solution to
                      our client in order to keep them updated outside word
                      technologically.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-12">
                <Heading
                  styles="absolute w-full top-12 text-center mx-auto  text-[34px] text-blue"
                  image="/assets/images/about us/SVG/Hire.svg"
                  text="Hire dedicated developers"
                />
                {/* old design */}
                {/* <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-12 gap-7"
                >
                  <div className="ring-2 ring-[#f7efd4] bg-gradient-to-tl from-white via-[#fef7df] to-white mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/pool.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Pool of expertise
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#edf1fe] bg-gradient-to-tl from-white via-[#edf1fe] to-white mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/skilled.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Experienced skilled resources
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#fee5e4] bg-gradient-to-tl from-white via-[#fee7e6] to-white  mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/communication.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Communication via skype/email/phone
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#f7efd4] bg-gradient-to-tl from-white via-[#fef7df] to-white mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/flexible.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Flexible pricing & working models
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#fee5e4] bg-gradient-to-tl from-white via-[#fee7e6] to-white mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/daily.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Daily/weekly reporting
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#f7efd4] bg-gradient-to-tl from-white via-[#fef7df] to-white mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/price.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Flexible pricing & working models
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#edf1fe] bg-gradient-to-tl from-white via-[#edf1fe] to-white mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/secure.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Secured & fail-safe environmen
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#fee5e4] bg-gradient-to-tl from-white via-[#fee7e6] to-white mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/dedicated.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Dedicated lead as single point of contact
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#edf1fe] bg-gradient-to-tl from-white via-[#edf1fe] to-white mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/expenses.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      No expenses on training & retaining
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#fee5e4] bg-gradient-to-tl from-white via-[#fee7e6] to-white mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/code.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Code & backup management
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#f7efd4] bg-gradient-to-tl from-white via-[#fef7df] to-white mx-auto relative w-[255px] h-[205px] rounded-lg ">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/cost.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Cost effective & on time delivery
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#edf1fe] bg-gradient-to-tl from-white via-[#edf1fe] to-white mx-auto relative w-[255px] h-[205px] rounded-lg">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/maintain.png"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-blue text-[18px]">
                      Maintenance and Support
                    </h1>
                  </div>
                </div> */}
                {/* new design */}
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-12 gap-7"
                >
                  <div className="ring-2 ring-[#2B747E] bg-[#F6FEFF] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/pool.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#2B747E] text-[18px]">
                      Pool of expertise
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#116EB4] bg-[#F6FBFF] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/skilled.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#116EB4] text-[18px]">
                      Experienced skilled resources
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#C24278] bg-[#FFF8FB] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/communication.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#C24278] text-[18px]">
                      Communication via skype/email/phone
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#8042C2] bg-[#FBF6FF] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/flexible.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#8042C2] text-[18px]">
                      Flexible pricing & working models
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#C24278] bg-[#FFF8FB] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/daily.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#C24278] text-[18px]">
                      Daily/weekly reporting
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#8042C2] bg-[#FBF6FF] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/price.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#8042C2] text-[18px]">
                      Flexible pricing & working models
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#2B747E] bg-[#F6FEFF] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/secure.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#2B747E] text-[18px]">
                      Secured & fail-safe environmen
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#116EB4] bg-[#F6FBFF] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/dedicated.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#116EB4] text-[18px]">
                      Dedicated lead as single point of contact
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#8042C2] bg-[#FBF6FF] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/code.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#8042C2] text-[18px]">
                      Code & backup management
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#116EB4] bg-[#F6FBFF] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/expenses.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#116EB4] text-[18px]">
                      No expenses on training & retaining
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#C24278] bg-[#FFF8FB] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/cost.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#C24278] text-[18px]">
                      Cost effective & on time delivery
                    </h1>
                  </div>
                  <div className="ring-2 ring-[#2B747E] bg-[#F6FEFF] mx-auto relative w-[255px] h-[205px] rounded-2xl">
                    <img
                      className="py-4 mx-auto"
                      src="/assets/images/about us/maintain.svg"
                      alt=""
                    />
                    <h1 className="text-center font-semibold px-2 text-[#2B747E] text-[18px]">
                      Maintenance and Support
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Quote
            sucessSnackBarOpen={sucessSnackBarOpen}
            setSucessSnackBarOpen={setSucessSnackBarOpen}
            warningSnackBarOpen={warningSnackBarOpen}
            setWarningSnackBarOpen={setWarningSnackBarOpen}
          />
        </div>
      </RouteAnimation>
    </>
  );
};

export default AboutUs;
