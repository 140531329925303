import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CarrerPoints = ({ text }) => {
  return (
    <div className="flex">
      <div className="mr-3">
        <ArrowForwardIcon color="primary" />
      </div>
      <div>
        <p className="text-[16px] esm:text-[18px] text-blue-600">{text}</p>
      </div>
    </div>
  );
};

export default CarrerPoints;
