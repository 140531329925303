import React, { useEffect, useState } from "react";
import Header from "../../../components/AdminPanelComponents/Header";
import Sidebar from "../../../components/AdminPanelComponents/Slidebar";
import { IconButton, Pagination, Button } from "@mui/material";
import { Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../../helper/api";
import { SMARTIMAGES } from "../../../helper/endpoints";
import EditIcon from "@mui/icons-material/Edit";
import Add from "./Add";
import Edit from "./Edit";
import SucessSnackBar from "../../../components/SnackBars/SnackBar";
import ErrorSnackBar from "../../../components/SnackBars/SnackBar";

const SmartImageManagment = () => {
  const [addModel, setAddModel] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [productData, setProductData] = useState();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [editmodel, setEditmodel] = useState(false);
  const [myedit, setMyedit] = useState("");
  const [sucessSnackBarOpen, setSucessSnackBarOpen] = useState(false);
  const [editSucessSnackBarOpen, setEditSucessSnackBarOpen] = useState(false);
  const [deleteSucessSnackBarOpen, setDeleteSucessSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [editWarningSnackBarOpen, setEditWarningSnackBarOpen] = useState(false);
  const [deleteWarningSnackBarOpen, setDeleteWarningSnackBarOpen] = useState(false);

  useEffect(() => {
    setProductData(prevState => prevState?.reduce((acc, { _id, smartimages }) => {
      smartimages?.forEach((image) => {
        acc.push({ _id, images: image });
      });
      return acc;
    }, []))
  }, [page])


  const getContact = async () => {
    api
      .get(`${process.env.REACT_APP_API + SMARTIMAGES}`)
      .then((res) => {
        setTotalPage(Math.ceil(res.data.data.length / 5));
        setProductData(res.data.data.reduce((acc, { _id, smartimages }) => {
          smartimages.forEach((image) => {
            acc.push({ _id, images: image });
          });
          return acc;
        }, []).slice((page - 1) * 5, page * 5));
      })
      .catch((err) => console.log(err));
  };

  const handelDelete = (data) => {
    api.deleteWithToken(`${process.env.REACT_APP_API + SMARTIMAGES}/${data.id}`, {
      updateimage: data.imagename
    }).then((res) => {
      getContact();
      setDeleteSucessSnackBarOpen(true)
    })
      .catch(err => {
        setDeleteWarningSnackBarOpen(true)
      });
  };

  const handelEdit = (data) => {
    setMyedit(data);
    setEditmodel(true);
  };

  useEffect(() => {
    getContact();
  }, [page]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className=" bg-white container mx-auto">
          <div className="p-5 w-full">
            <header className="flex justify-between relative py-4 border-b border-slate-100">
              <h2 className="text-sm  font-semibold text-slate-800">
                All Images
              </h2>

              <div className="flex gap-1 ">
                <div>
                  <Button
                    onClick={() => setAddModel(true)}
                    variant="contained"
                    sx={{ color: "white" }}
                  >
                    Add Image
                  </Button>
                </div>
              </div>

              <Add
                addModel={addModel}
                setAddModel={setAddModel}
                getContact={getContact}
                setSucessSnackBarOpen={setSucessSnackBarOpen}
                setWarningSnackBarOpen={setWarningSnackBarOpen}
              />

              <Edit
                myedit={myedit}
                editmodel={editmodel}
                setEditmodel={setEditmodel}
                getContact={getContact}
                setEditSucessSnackBarOpen={setEditSucessSnackBarOpen}
                setEditWarningSnackBarOpen={setEditWarningSnackBarOpen}
              />
            </header>

            <div className="overflow-x-auto">
              <table className=" w-full table-auto">
                <thead className=" font-semibold uppercase text-slate-400 bg-slate-50">
                  <tr>
                    <th className=" p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Image Name
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Image
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="text-[14px] font-semibold text-left">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>

                {productData?.map((object, index) => (
                  <tbody key={index}>
                    <tr className="border-b-2 hover:bg-slate-50">
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[20px] text-left text-blue">
                          {object.images}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <img className="h-[100px] w-[100px]" src={process.env.REACT_APP_UPLOADS + object.images} alt="uploded-img" />
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-[#1e293b]">
                          <IconButton
                            onClick={() => {
                              handelDelete({
                                id: object._id,
                                imagename: object.images
                              });
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            onClick={() => handelEdit({
                              id: object._id,
                              imagename: object.images
                            })}
                          >
                            <EditIcon />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
          <nav className="p-3" aria-label="Page navigation example">
            <Stack spacing={2}>
              <Pagination
                count={totalPage}
                page={page}
                pages={totalPage}
                onChange={(e, value) => setPage(value)}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </nav>
        </div>
      </div>

      <SucessSnackBar
        open={sucessSnackBarOpen}
        setOpen={setSucessSnackBarOpen}
        text="Image Added Successfully !"
        type="success"
      />
      <ErrorSnackBar
        open={warningSnackBarOpen}
        setOpen={setWarningSnackBarOpen}
        text="Something Missing !"
        type="error"
      />

      <SucessSnackBar
        open={editSucessSnackBarOpen}
        setOpen={setEditSucessSnackBarOpen}
        text="Image Edited Successfully !"
        type="success"
      />
      <ErrorSnackBar
        open={editWarningSnackBarOpen}
        setOpen={setEditWarningSnackBarOpen}
        text="Something Missing !"
        type="error"
      />

      <SucessSnackBar
        open={deleteSucessSnackBarOpen}
        setOpen={setDeleteSucessSnackBarOpen}
        text="Image Deleted Successfully !"
        type="success"
      />
      <ErrorSnackBar
        open={deleteWarningSnackBarOpen}
        setOpen={setDeleteWarningSnackBarOpen}
        text="Something Missing !"
        type="error"
      />
    </div >
  );
};

export default SmartImageManagment;