import RouteAnimation from "../../components/helper/RouteAnimation";
import HomeMain from "../../components/HomeMain/HomeMain";
import HomeSection2 from "../../components/HomePage/HomeSection2";
import WhySmartTechnica from "../../components/HomePage/WhySmartTechnica";
import Review from "../../components/HomePage/Review";
import OneRoof from "../../components/HomePage/OneRoof";
import Offer from "../../components/HomePage/Offer";
import Skills from "../../components/HomePage/Skills";

const Home = () => {
  return (
    <RouteAnimation>
      <HomeMain />
      <HomeSection2 />
      <WhySmartTechnica />
      <Review />
      <OneRoof />
      <Offer />
      <Skills />
    </RouteAnimation>
  );
};

export default Home;
