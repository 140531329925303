import React from "react";
import Heading from "../../components/helper/Heading";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const clientReviews = [
  {
    Review: `Smart Technica team are amazing. It's hard to find a developer
              with the same level of knowledge and dedication to perfection
              anywhere in today's age. I have worked with Dipen & Hemal for more
              than 12 months. Everything we wanted ~ they delivered 110%. Their
              execution of additions and changes was lightning quick. My
              requirement is complex. However, Hemal and Dipen always seemed to
              solve and surpass all of my concerns and expectations`,
    clientImage: "/assets/images/clients/hanery.png",
    clientName: "Henry",
    country: "United States",
  },
  {
    Review: `Smart Technica team demonstrated high level of professionalism and
              performed brilliantly throughout the project. their experience
              made my project a success and I’m was delighted to have given my
              project to them. I am a person who invests painstaking attention
              into the tiniest of details, and working with Dipen, Hemal and
              their team made it so much easier.`,
    clientImage: "/assets/images/clients/sydney.jpeg",
    clientName: "Sydney",
    country: "South Africa",
  },
  {
    Review: `Team is very professional and easy to work with. They have a lot
              of patience in understanding the requirements and ensuring the
              best output is achieved. It also helps that they are highly
              skilled in web development, so you can trust and start building
              creative features. Look forward to working with them in many
              future projects.`,
    clientImage: "/assets/images/clients/valerina.jpg",
    clientName: "Valerina",
    country: "UAE",
  },
  {
    Review: `Truly a great team to work with! Understanding, patient, skilled,
              and delivered excellence with our project. I got whole solution
              for app development under one roof. Highly recommend & I can’t
              express my gratitude to them enough.`,
    clientImage: "/assets/images/clients/blondin.jpeg",
    clientName: "Blondin",
    country: "United States",
  },
  {
    Review: `If you are building a business, you need a team who is dedicated
              to you and who understands you, Here are those guys..... Would
              always prefer these guys higher than any other that I have ever
              worked with, and at time would also rate them higher than my own
              employees here....EXCELLENT`,
    clientImage: "/assets/images/clients/david.png",
    clientName: "David",
    country: "Canada",
  },
  {
    Review: `Great work done by the team. Highly satisfied with the
              deliverables and recommend for App, Website & CRM development.
              Have been available round the clock for any communication.`,
    clientImage: "/assets/images/clients/subhash.png",
    clientName: "Subhash",
    country: "India",
  },
  {
    image: "/assets/images/home/quots.png",
    Review: `Wow! Amazing work done by Smart Technica team. They are very much
              supportive. Smart Technica team is very kind and genius. they did
              really fantastic work. team continues giving support i will highly
              recommend them again. Thank you team Smart Technica.`,
    clientImage: "/assets/images/clients/dionne.jpeg",
    clientName: "Dionne",
    country: "USA",
  },
];

const Review = () => {
  const options = {
    margin: 30,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  return (
    <div className="mt-20 max-w-[95%] md:max-w-[85%] lg:max-w-[80%] mx-auto">
      <Heading
        styles="absolute w-full top-12 text-center mx-auto"
        image="/assets/images/home/Review.svg"
        text="What our client saying"
      />
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5 "
      >
        <OwlCarousel
          className="slider-items owl-theme map-slider owl-carousel owl-loaded owl-drag"
          {...options}
          loop
          margin={10}
        >
          {clientReviews.map((res, i) => (
            <div key={i} className="mt-0 lg:mt-20">
              <img src="/assets/images/home/quots.png" alt="mainImage" />
              <p className="text-[18px] mt-4 opacity-80">{res.Review}</p>
              <div className="flex mt-5">
                <img
                  className="w-[64px] h-[64px] rounded-full"
                  src={res.clientImage}
                  alt={res.clientName}
                />
                <div className="ml-5">
                  <h1 className="text-blue text-[20px] font-medium">
                    {res.clientName}
                  </h1>
                  <p className="text-[16px]">{res.country}</p>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
        <div className="w-full">
          <img
            className="mx-auto"
            src="/assets/images/home/reviewImg.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Review;
