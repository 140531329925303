import React, { useState } from "react";
import "./Drawer.css";
import { Divider, Drawer, IconButton, List } from "@mui/material";
import { useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const StyleDrawer = styled(Drawer)(() => ({ 
  "& .MuiDrawer-paper": {
    width: 280,
    boxSizing: "border-box",
    backgroundColor: "#116ab7",
    Color: "white !important",
  },
}));

const DrawerComp = () => {
  const { pathname } = useLocation();

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <StyleDrawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <div className="flex justify-between items-center  ">
          <div className="bg-white h-[65px] m-[5px] flex  items-center rounded-xl">
            <img
              className="w-[150px] p-4 "
              src="/assets/images/home/smart.png"
              alt="logo"
            />
          </div>
          <div className="p-2 cursor-pointer">
            <CloseIcon
              onClick={() => setOpenDrawer(false)}
              sx={{ color: "#ffff" }}
            />
          </div>
        </div>
        <Divider sx={{ background: "white" }} />
        <List>
          <div className="">
            <ul className="" style={{ listStyleType: "none" }}>
              <li
                className={
                  pathname === "/"
                    ? "text-white pb-4 mt-3 underline-offset-4 underline decoration-white"
                    : "text-white pb-4  mt-3 "
                }
              >
                <Link
                  onClick={() => setOpenDrawer(false)}
                  className="pl-4 "
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li
                className={
                  pathname === "/services"
                    ? "text-white pb-4 underline-offset-4 underline decoration-white"
                    : "text-white pb-4 "
                }
              >
                <Link
                  onClick={() => setOpenDrawer(false)}
                  className="pl-4"
                  to="/services"
                >
                  Services
                </Link>
              </li>
              <li
                className={
                  pathname === "/about-us"
                    ? "text-white pb-4 underline-offset-4 underline decoration-white "
                    : "text-white pb-4"
                }
              >
                <Link
                  onClick={() => setOpenDrawer(false)}
                  className="pl-4"
                  to="/about-us"
                >
                  About Us
                </Link>
              </li>
              <li
                className={
                  pathname === "/portfolio"
                    ? "text-white pb-4 underline-offset-4 underline decoration-white "
                    : "text-white pb-4"
                }
              >
                <Link
                  onClick={() => setOpenDrawer(false)}
                  className="p-4"
                  to="/portfolio"
                >
                  Protfolio
                </Link>
              </li>
              <li
                className={
                  pathname === "/career"
                    ? "text-white pb-4 underline-offset-4 underline decoration-white"
                    : "text-white pb-4"
                }
              >
                <Link
                  onClick={() => setOpenDrawer(false)}
                  className="p-4"
                  to="/career"
                >
                  Career
                </Link>
              </li>
              <li
                className={
                  pathname === "/contact-us"
                    ? "text-white pb-4 underline-offset-4 underline decoration-white "
                    : "text-white pb-4"
                }
              >
                <Link
                  onClick={() => setOpenDrawer(false)}
                  className="p-4"
                  to="/contact-us"
                >
                  Contact
                </Link>
              </li>
              <li
                className={
                  pathname === "/request-for-quote"
                    ? "text-white pb-4 underline-offset-4 underline decoration-white "
                    : "text-white pb-4"
                }
              >
                <Link
                  onClick={() => setOpenDrawer(false)}
                  className="p-4"
                  to="/request-for-quote"
                >
                  Request For Quote
                </Link>
              </li>
            </ul>
          </div>
        </List>
        <Divider sx={{ background: "white" }} />
      </StyleDrawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon sx={{ color: "#116ab7" }} />
      </IconButton>
    </>
  );
};

export default DrawerComp;
