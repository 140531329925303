import React from "react";

const Heading = (props) => {
  const { styles, image, text } = props;

  return (
    <div data-aos="fade-down" data-aos-duration="1500" className="relative ">
      <img className="mx-auto" src={image} alt="" />
      <p className={`${styles} leading-6 esm:leading-8 text-[26px] esm:text-[30px] text-blue font-medium`}>
        {text}
      </p>
    </div>
  );
};

export default Heading;
